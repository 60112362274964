import newDektopSelect from '~/javascripts/app/dektop_select2.js';
import { GetImageUrl} from "~/javascripts/utils.js"

import { Modal } from 'bootstrap'

// TODO: 跟index整合
export default function InitUserStoreEditPage() {
  const pageID = "users-stores-edit"
  const page   = document.getElementById(pageID)
  if (!page) return false;
  document.addEventListener("DOMContentLoaded", () => {
    handlePopupCookie();
    newDektopSelect();
    editInit();
    uploadPreviewImg();
  });
}

function editInit(){
  const editBtn = document.querySelector('.js-edit-btn');

  const showBlocks = document.querySelectorAll('.js-show-block');
  const editBlocks = document.querySelectorAll('.js-edit-block')
  const formBtnBlock = document.querySelector('.js-form-btns');

  if(editBtn){
    editBtn.onclick = (e) => {
      showBlocks.forEach(block => {
        block.style.display = "none";
      });
      editBlocks.forEach(block => {
        block.style.display = "";
      });
      editBtn.style.display = "none";
      formBtnBlock.style.display = "";

      // img
      const originalCoverImg = document.querySelector('.original-cover-img');
      const deleteImgBtn = document.querySelector('.js-delete-preview-img');
      if(deleteImgBtn) deleteImgBtn.addEventListener('click',(e) => clearPreviewImage(e, originalCoverImg));

      const backToViewBtn = document.querySelector('.js-back-to-view');
      backToViewBtn.onclick = () => {
        window.location.reload();
      }
    }
  }

}

function uploadPreviewImg(){
  const imagePreviewInput = document.querySelector('.js-image-preview-input');
  const originalCoverImg = document.querySelector('.original-cover-img')

  if(imagePreviewInput){
    imagePreviewInput.addEventListener('change',(e)=>{
      const file = e.target.files[0]
      if(file){
        var reader = new FileReader();

        reader.onload = function (readerEvent) {
          refreshCoverImgLabel(readerEvent.target.result, originalCoverImg)
        };

        reader.readAsDataURL(file); // 讀取圖片文件，轉換成 Data URL
      }
    })
  }
}

// 刷新前端照片區域
function refreshCoverImgLabel(img_result, originalCoverImg) {
  const destroyInput = document.getElementById('store_cover_img_destroy')
  if(destroyInput) destroyInput.remove();
  console.log(destroyInput)

  const imagePreview = document.querySelector('.js-image-preview');
  imagePreview.innerHTML = ''; // 清空預覽區域
  imagePreview.innerText = '店鋪封面';
  let imageElement = document.createElement('img');
  imageElement.src = img_result;
  imageElement.alt = 'PreviewImage';
  imageElement.style.width = '50%';
  imagePreview.appendChild(imageElement);
  let iElement = document.createElement('i');
  iElement.classList.add("bi","bi-trash3", "js-delete-preview-img");
  iElement.addEventListener('click', (e) => clearPreviewImage(e, originalCoverImg))
  imagePreview.appendChild(iElement);
}

// 刪除前端預覽照片
function clearPreviewImage(e, deleteImg){
  e.preventDefault();

  const imagePreview = document.querySelector('.js-image-preview');
  imagePreview.innerHTML = ''; // 清空預覽區域
  imagePreview.innerText = '店鋪封面';
  let imageElement = document.createElement('img');
  imageElement.src = GetImageUrl('index/pic-icon.png')
  imagePreview.appendChild(imageElement);
  let divElement = document.createElement('div');
  divElement.textContent = '點擊上傳';
  imagePreview.appendChild(divElement);

  const originalInput = document.querySelector('.js-image-preview-input');
  originalInput.value = null;

  if(deleteImg) clearCoverImgInput(deleteImg);
}

// 刪除後端圖片
function clearCoverImgInput(deleteImg){
  const originalInput = document.querySelector('.js-image-preview-input');
  const deleteID = deleteImg.getAttribute('data-cover-img-id')
  const newElement = document.createElement('input');
  newElement.setAttribute('type', 'hidden');
  newElement.innerHTML = deleteID; // 添加你想要的内容或其它元素
  newElement.setAttribute('name', `store[cover_img_destroy]`);
  newElement.setAttribute('id', `store_cover_img_destroy`);
  newElement.value = true
  originalInput.parentNode.insertAdjacentElement('afterend', newElement);
}

function handlePopupCookie(){
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  if(getCookie("submit_popup")){
    console.log('has popup cookie')
    document.cookie = `submit_popup=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Path=/`;
    const applyReviewModal= new Modal(document.getElementById('applyReviewModal'));
    applyReviewModal.show();
  }
}











