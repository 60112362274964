import axios from "axios"
import { Modal } from 'bootstrap'
import NewInputfeedback from '~/javascripts/app/input/input_feedback.js'
import {toastr_error} from "~/javascripts/toastr.js"
import {HandleErrorResponse} from "~/javascripts/utils.js"

const modalRemoveDesignSelector = ".js-modal-remove-design";
const modalRenameDesignSelector = ".js-modal-rename-design";
const modalRenameDesignNameSelector = ".js-modal-rename-design-name";

class ModalRemoveDesignListener {
  constructor(bsModalEl) {
    this.bsModalEl = bsModalEl;

    this.bsModalEl.addEventListener('show.bs.modal', event => {
      const relatedDiv = event.relatedTarget;
      const designID = relatedDiv.getAttribute('data-bs-design-id');
      const submitBtn = this.bsModalEl.getElementsByTagName('button').submit;
      
      submitBtn.onclick = () => {
        const removeDesignModal = Modal.getInstance(this.bsModalEl);

        axios.delete(`/api/designs/${designID}`)
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          HandleErrorResponse(e, () => {
            removeDesignModal?.hide();
            toastr_error(e.response.data.message);
          });
        })
      }
    })
  }
}

class ModalRenameDesignListener {
  constructor(bsModalEl) {
    this.bsModalEl = bsModalEl;
    const modalNameInput = this.bsModalEl.querySelector('.modal-body #design_name');
    const nameFeedback = NewInputfeedback(modalNameInput);


    this.bsModalEl.addEventListener('show.bs.modal', event => {
      nameFeedback.clear();

      const relatedDiv = event.relatedTarget;
      const designID = relatedDiv.getAttribute('data-bs-design-id');
      const designName = relatedDiv.getAttribute('data-bs-design-name');
      const submitBtn = this.bsModalEl.getElementsByTagName('button').submit;
      
      modalNameInput.value = designName;

      submitBtn.onclick = () => {
        const renameDesignModal = Modal.getInstance(this.bsModalEl)
        const newNameInput = modalNameInput.value

        const params = {
          name: newNameInput
        }

        axios.post(`/api/designs/${designID}/rename_design`, params)
        .then(() => {
          window.location.reload();
          // item element
          // let targetDesignItems = document.querySelectorAll(`#design-item-${designID}`);
          // targetDesignItems.forEach(targetItem => {
          //   // change dataset bs-design-name value
          //   let dropdownItems = targetItem.querySelectorAll('.dropdown-item');
          //   dropdownItems.forEach(itemTag => {
          //     itemTag.dataset.bsDesignName = newNameInput;
          //   })
          // })

          // change html target design name(modalRenameDesignNameSelector class)
          let nameEls = document.querySelectorAll(modalRenameDesignNameSelector)
          nameEls.forEach(nameEl => {
            if (nameEl.dataset?.designId == designID) nameEl.textContent = newNameInput;
          })

          renameDesignModal?.hide();
        })
        .catch((e) => {
          HandleErrorResponse(e, nameFeedback.setInvalidMsg(e.response.data.message));
        })
      }
    })
  }
}

export default function UsersDesignsIndexPage() {
  document.querySelectorAll(modalRemoveDesignSelector).forEach((el) =>{
    new ModalRemoveDesignListener(el);
  });
  document.querySelectorAll(modalRenameDesignSelector).forEach((el) =>{
    new ModalRenameDesignListener(el);
  });
}