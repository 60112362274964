<template>
  <div class="modal-content wallet-modal__content">
    <div v-if="isLoading" class="wallet-modal__loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="modal-header wallet-modal__header" >
      提款
      <i class="bi bi-x close-btn" role="button" title="關閉" data-bs-dismiss="modal"></i>
    </div>
    <template v-if="!isComplete">
      <div class="modal-body withdrawal-container">
        <div class="wallet-modal__section">
          <div class="withdrawal-container__title">從摩客錢包提款至</div>
          <div>{{ withdrawInfo.bank_name }} * {{ withdrawInfo.transferee_card }}</div>
        </div>
        <div class="wallet-modal__section">
          <div class="withdrawal-container__title">提款金額</div>
          <div class="withdrawal-panel">
            <div class="withdrawal-panel__amount">
              <label for="amountInput" class="'withdrawal-panel__amount--label amount-text">NT$</label>
              <input type="text" name="amountInput" id="amountInput" v-model="amountInput" @input="amountInputValidation"
                class='withdrawal-panel__amount--input'
                placeholder='0'
                autocomplete='off'
                :readonly="balanceIschecked"
              >
            </div>
            <div class="withdrawal-panel__balance">
              <input type="checkbox" name="balance" id="balance" v-model="balanceIschecked"
                class="withdrawal-panel__balance--input form-check-input"
              >
              <label class="withdrawal-panel__balance--label" for="balance">提領餘額: <span>NT$ {{ formatCurrency(withdrawInfo.balance) }}</span></label>
            </div>
            <div v-if="amountIsLow" class="withdrawal-panel__error">提款金額需大於${{ formatCurrency(withdrawInfo.min_withdraw) }}</div>
            <div v-if="amountIsHigh" class="withdrawal-panel__error">提款金額需小於${{ formatCurrency(withdrawInfo.balance) }}</div>
          </div>
        </div>
        <div class="wallet-modal__section wallet-modal__section--last">
          <div class="withdrawal-container__title"><span>提款手續費</span><span>NT$ {{ formatCurrency(withdrawInfo.handling_fee) }}</span></div>
          手續費只會在成功提領的交易收取
        </div>
        <div class="wallet-modal__tips">
          <i class="bi bi-exclamation-circle"></i>申請提領後約3-5個工作天入帳(不含假日)
        </div>
      </div>
      <div class="modal-footer wallet-modal__footer">
        <div class="withdrawal-summary-block">
          <div>入帳金額</div>
          <div class="amount-text">NT$ <span>{{ formatCurrency(finalAmount) }}</span></div>
        </div>
        <button class="mk-btn next-step-btn" @click="handleWithdrawMoney">下一頁</button>
      </div>
    </template>
    <template v-else>
      <div class="modal-body withdrawal-container">
        <div class="wallet-modal__section">
          <div class="withdrawal-container__title">從摩客錢包提款至</div>
          <div>{{ withdrawSuccessionInfo.bank_name }} * {{ withdrawSuccessionInfo.transferee_card }}</div>
        </div>
        <div class="wallet-modal__section wallet-modal__section--last pb-0">
          <ul class="wallet-modal__list-row">
            <li><span>提款總額</span><span>NT${{ formatCurrency(withdrawSuccessionInfo.amount) }}</span></li>
            <li><span>提款手續費</span><span>NT${{ formatCurrency(withdrawSuccessionInfo.handling_fee) }}</span></li>
            <li><span>入帳金額</span><span class="amount-text">NT${{ formatCurrency(withdrawSuccessionInfo.final_amount) }}</span></li>
          </ul>
        </div>
        <div class="wallet-modal__tips">
          <i class="bi bi-exclamation-circle"></i>申請提領後約3-5個工作天入帳(不含假日)
        </div>
      </div>
      <div class="modal-footer wallet-modal__footer wallet-modal__footer--no-border">
        <div class="mk-btn go-back-btn" data-bs-dismiss="modal">返回</div>
      </div>
    </template>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits, computed, reactive, watch, onMounted } from 'vue'
  import axios from 'axios';
  import { HandleErrorResponse } from "~/javascripts/utils.js";

  const withdrawInfo = reactive({
    balance      : 0, // amount -> balance
    bank_code    : "",
    bank_name    : "",
    transferee_card: "",
    handling_fee : 0,
    min_withdraw : 0,
  })

  const withdrawSuccessionInfo = reactive({
    bank_code    : "",
    bank_name    : "",
    transferee_card: "",
    amount       : 0,
    handling_fee : 0,
    final_amount : 0,
  })

  const amountInput = ref('');
  const balanceIschecked = ref(false);
  const amountIsLow = ref(false);
  const amountIsHigh = ref(false)
 
  const isLoading = ref(false);
  const isComplete = ref(false);

  const withdrawAmount = computed(() => StringToNumber(amountInput.value))
  const finalAmount = computed(() => withdrawAmount.value - withdrawInfo.handling_fee < 0 ? 0 : withdrawAmount.value - withdrawInfo.handling_fee)

  watch(balanceIschecked, (newV) => {
    if(newV) {
      amountInput.value = withdrawInfo.balance
    } else {
      amountInput.value = ''
    }
  })

  onMounted(() => {
    const withdrawalModal = document.querySelector(".js-withdrawal-modal")
    if(withdrawalModal) {
      withdrawalModal.addEventListener('show.bs.modal' , () => {
        getWithdrawInfo();
      })
      withdrawalModal.addEventListener('hide.bs.modal', () => {
        if(isComplete.value) {
          location.reload()
        }
        balanceIschecked.value = false;
        amountInput.value = '';
        amountIsLow.value = false;
        amountIsHigh.value = false;
        isComplete.value = false;
      });
    }
  });

  const getWithdrawInfo = () => {
    axios.get(`/api/wallets/withdraw`).then((resp) => {
      const data = resp.data.data
      withdrawInfo.balance      = StringToNumber(data.amount);
      withdrawInfo.bank_code    = data.bank_code;
      withdrawInfo.bank_name    = data.bank_name;
      withdrawInfo.transferee_card  = data.transferee_card;
      withdrawInfo.handling_fee = StringToNumber(data.handling_fee);
      withdrawInfo.min_withdraw = data.min_withdraw
    }).catch((err) => {
      HandleErrorResponse(err)
    })
  }

  const handleWithdrawMoney = () => {
    if(withdrawAmount.value < 300) {
      amountIsLow.value = true;
      return;
    }
    
    if(finalAmount.value > withdrawInfo.balance ) {
      amountIsHigh.value = true
      return;
    }

    const params = {
      amount :withdrawAmount.value
    }
    isLoading.value = true;
   
    axios.post(`/api/wallets/withdraw_money`, params).then((resp) => {
      isLoading.value = false;
      isComplete.value = true;
      const data = resp.data.data;
      withdrawSuccessionInfo.bank_code    = data.bank_code;
      withdrawSuccessionInfo.bank_name    = data.bank_name;
      withdrawSuccessionInfo.transferee_card = data.transferee_card;
      withdrawSuccessionInfo.amount       = StringToNumber(data.amount)
      withdrawSuccessionInfo.handling_fee = StringToNumber(data.handling_fee);
      withdrawSuccessionInfo.final_amount = StringToNumber(data.final_amount)
    }).catch((err) => {
      HandleErrorResponse(err, () => {isLoading.value = false;})
    })
  }

  const amountInputValidation = () => {
    // 使用正则表达式匹配输入值是否为非负整数
    if (!/^\d*$/.test(amountInput.value)) {
      // 将非法字符替换为合法字符
      amountInput.value = amountInput.value.replace(/[^\d]/g, '');
    }
  }

  const StringToNumber = (value) => { // 四捨五入
    const stringValue = value.toString();
    const validNumber = /^-?\d*\.?\d+$/.test(stringValue.trim());
    return validNumber ? Math.round(parseFloat(stringValue)) : 0;
  }

  const formatCurrency = (value,format) => {
    // 检查value是否是数字
    if (isNaN(value)) {
      return value; // 如果不是数字，直接返回原始值
    }
    // 使用toLocaleString()方法将数字格式轉化,超過位數四捨五入
    return value.toLocaleString('zh-TW', { style: format, currency: 'TWD',minimumFractionDigits: 0, maximumFractionDigits: 0});
  };
</script>

<style lang="scss" scoped>
$mk-orange:#ee7c26;
.withdrawal-container {
  padding: 0;
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    font-weight: bolder;
    color: #000000;
  }
  .withdrawal-panel {
    &__amount {
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #B3B3B3;
      padding: 0.5rem;
      font-size: 2rem;
      font-weight: bolder;
      letter-spacing: 5px;
      &--input {
        outline: none;
        border: none;
        text-align: start;
        min-width: 0;
        &::placeholder {
          text-align: start;
        }
        // &:-webkit-autofill {
        //   background: transparent;
        //   transition: background-color 5s ease-in-out 0s;
        //   -webkit-text-fill-color: unset;
        // }
      }
    }
    &__balance {
      display: flex;
      align-items: center;
      padding: 0.25rem 0.5rem 0;
      &--input {
        box-shadow: none;
        outline: none;
        background-color: rgba(0,0,0,0);
        width: 1.5rem;
        height: 1.5rem;
        &:focus {
          border-color: rgba(0, 0, 0, 0.25);
        }
        &:checked {
          border-color: $mk-orange;
          background-color: $mk-orange;
        }
      }
      &--label {
        margin-left: 0.5rem;
        font-weight: bolder;
        color: #000000;
      }
    }
    &__error {
      text-align: end;
      color: red;
      font-size: 12px;
      &--hidden {
        display: none;
      }
    }
  }
}

.withdrawal-summary-block{
  margin-right: 1rem;
  text-align: end; 
  > div:first-of-type {
    margin-bottom: 0.25rem;
  }
}
</style>