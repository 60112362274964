<template>
  <div class="landing-shopping-block landing-shopping-cart">
    <div class="landing-shopping-cart__title">目前已經選購</div>
    <div v-if="selectedProductList.length" class="landing-shopping-cart__product-list">
      <div v-for="(selectedProduct,index) in selectedProductList" :key="index" class="product-block">
        <div class="product">
          <div class="product__btn trash-btn" role="button" @click="cancelSelectedProduct(selectedProduct)">
            <i class="bi bi-trash3"></i>
          </div>
          <div class="product__image">
            <div class="mk-image-box" @click="zoomImageAnimationHandler">
              <img :src='selectedProduct.image' alt="image">
            </div>
          </div>
          <div class="product__info">
            <div>{{ selectedProduct.name }},{{ selectedProduct.size }}</div>
            <div>
              NT$&nbsp;{{ selectedProduct.item_price }}&nbsp; <span class="count">*&nbsp;{{ selectedProduct.quantity }}</span>
            </div>
            <div class="btn-group fw-semibold">
              <button @click="DecrementItem(selectedProduct)" type="button" 
                class="btn btn-counter px-3 inner-text" style="--bs-btn-font-weight: 900;">-</button>
              <p class="mx-1 my-0 w-quantity">{{ selectedProduct.quantity }}</p>
              <button @click="IncrementItem(selectedProduct)" type="button"
                class="btn btn-counter px-3 inner-text rounded-end" style="--bs-btn-font-weight: 900;">+</button>
            </div>           
          </div>
        </div>
        <div class="product-cost">NT$&nbsp;{{ selectedProduct.sub_total }}</div>
      </div>
    </div>
    <div v-else class="landing-shopping-cart__product-list default">
        <div>購物車「沒有產品」，請先將產品「加入購物車」</div>
    </div>
    <div class="landing-shopping-cart__summary">
      <div class="total-cost-block">
        <span>小計</span>
        <span class="total-cost-block__cost">NT$&nbsp;{{ selectedProductTotalPrice }}</span> 
      </div>
    </div>
    <div class="checkout-btn mk-btn" role="button" @click="handleLandingCheckout">
      <i class="bi bi-cart"></i><span>立即結帳</span>  
    </div>
    <hr/>
    <LandingShoppingCartStatusModal
      :statusModalSetting="statusModalSetting"
      :statusModalShow="statusModalShow"
    />

    <PayModal ref="payModal" :checkOutForm="shoppingCart.check_out_form" :paymentOptions="shoppingCart.payment_options"
          :order_amount="shoppingCart.total_price" :cities="shoppingCart.cities" :shippingOptions="shoppingCart.shipping_options"
          :landingSale="landingSale"
    />
  </div>
</template>

<script setup>
import { GetImageUrl } from "~/javascripts/utils.js";
import { ref, defineProps, defineEmits, computed, reactive,watch,onMounted } from 'vue'
import {useLandingSalseStore} from './landing_sales_store.js';
import PayModal from "../../checkout/components/PayModal.vue";
import LandingShoppingCartStatusModal from "./landing_shopping_cart_status_modal.vue";
import {zoomImageAnimationHandler} from '~/javascripts/app/zoom_in_image.js';

  const store = useLandingSalseStore();
  const shoppingCart = computed(()=>store.getShoppingCart);

  const selectedProductList = ref([]);
  const selectedProductTotalPrice = ref(0);

  const landingSale = computed(() => {
    return {
      isVisitor: true,
      slug: store.api,
    }
  });

  const statusModalShow = ref(false) //不用管是true or false，只是一個觸發器

  const statusModalSetting = reactive({
    status:'',
    msg:'',
  })

  onMounted(async () => {    
    try {
      await store.initShoppingCartInfo();
      // check out 

      if (store.data.check_out_form?.step == 2 && store.data.items.length > 0) {
        openModal(2);
      } else if (store.data.check_out_form?.step == 3) {
        openModal(3);
      }
    } catch (err) {
      console.log(err)
    }
  });
  

  watch(shoppingCart,(newValue)=>{

    selectedProductList.value = newValue.items;
    selectedProductTotalPrice.value = newValue.total_price;
    // 當購物車有物件且step = 2   
  }, {deep:true})

  const IncrementItem = async (item) => {
    try {
      await store.updateShoppingCart(item, item.quantity + 1, 'update')
    } catch (err) {
      console.log('error')
    }
  }
  const DecrementItem = async (item) => {
    if (item.quantity > 1) {
      try {
        await store.updateShoppingCart(item, item.quantity - 1, 'update');
      } catch (err) {
        console.log('error')
      }
    }
  }

  const cancelSelectedProduct = async (item) => {
    try {
      await store.updateShoppingCart(item, item.quantity, 'delete');
    } catch (err) {
      console.log('error')
    }
  };

  const handleLandingCheckout = async () =>{
    if(selectedProductList.value.length > 0) {
      try {
        await store.landingCheckout();
        await store.initShoppingCartInfo();
        openModal(1);
      } catch (err) {
        console.log('error');
      }
    } else {
      statusModalShow.value = !statusModalShow.value;
      statusModalSetting.status = 'error';
      statusModalSetting.msg = '購物車內沒有商品';
    }
  }

  const payModal = ref();
  function openModal(target) {
    if (target) {
      payModal.value.showTargetStep(target);
    } else {
      payModal.value.show();
    }
  }

</script>

<style scoped lang="scss">
$mk-orange:#ee7c26;
.landing-shopping-cart{
  &__title{
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    margin: 2.5rem 0;
  }
  &__product-list{
    .product-block{
      position: relative;
      padding:2rem 1rem;
      border-bottom: 1px solid #999;
      background-color: #fff;
      .product{
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
        &__btn{
          margin:0 1rem;
        }
        &__image{
          margin:0 1rem ;
          border: 1px solid #bbb;
          width: 130px;
        }
        &__info{
          color: black;
        }
      }
      .product-cost{
        position: absolute;
        top: 35px;
        right: 2rem;
      }
      
    }
  }
  &__product-list.default{
    border-bottom: 1px solid #999;
    height: 150px;
    background-color: #fff;
    text-align: center;
    line-height: 150px;
    font-size:1.5rem;
  }
  &__summary{
    margin-bottom: 1.75rem;
    padding: 0 2rem;
    background-color: #fff;
    .total-cost-block{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto 0;
      min-height: 60px;
      &__cost{
        font-weight: 900;
      }
    }
  }
  .trash-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ddd;
    width:2.25rem;
    height:2.25rem;
    i{
      font-size: 1.15rem;
    }
  }
  .product__info{
    >div{
      margin-bottom: 1rem;
      @media screen and (max-width: 768px) {
        margin-bottom: 0.5rem;
      }
    }
    .count{
      font-weight: 600;
      color: rgba(249, 72, 22, 1);
    }
  }
  .checkout-btn{
    width: 100%;
    font-size: 1.1em;
    border-radius: 20px;
    i{
      margin-right:5px ;
    }
  }
}

@media screen and (max-width: 768px) {
  .landing-shopping-cart{
    &__title{
      margin: 1.5rem 0;
      font-size:1.5rem ;
    }
    &__product-list{
      .product-block{
        position: static;
        padding:1rem 0.5rem 1rem;
        .product{
          &__btn{
            margin: 0 0.5rem;
          }
          &__image{
            width: 80px;
          }
          &__info{
            color: black;
          }
        }
        .product-cost{
          position: static;
          margin-right: 1rem;
          text-align: end;
        }
      }
    }
    &__product-list.default{
      height: 120px;
      line-height: 120px;
      font-size:1rem;
    }
    &__summary{
      padding: 0 1.5rem;
    }
    .trash-btn{
      width: 2rem;
      height: 2rem;
      i{
        font-size: 1rem;
      }
    }
    .checkout-btn{
      display: block;
      margin: 0 auto;
      width: 95%;
    }
    .product__info{
      >div{
        margin-bottom: 0.5rem;
      }
    }
  }
}

// counter btn
.inner-text {
  font-size: 20px;
  line-height: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-group {
  align-items: center;
}

.btn-counter {
  --bs-btn-color: #000;
  --bs-btn-bg: #D9D9D9;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D9D9D9;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-btn-disabled-opacity: 1;
}
.w-quantity {
  width: 26px;
  text-align: center;
}
</style>