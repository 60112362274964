let currentSelectOption = null;

export default function InitProductPage() {
  const pageID = "product-page" 
  const page   = document.getElementById(pageID)
  if (!page) return false;

  document.addEventListener("DOMContentLoaded", () => {
    initProductOptions();
  });
}

function initProductOptions() {
  const selectBoxSelector = "#js-product-color-select-box";
  const selectBox = document.querySelector(selectBoxSelector);
  
  // set first checked options
  const optionSelector = ".js-product-select-option";
  checkProductOption(selectBox.querySelector(optionSelector))

  const options = selectBox.querySelectorAll(optionSelector)
  options.forEach(opt => {
    // set option color
    const colorCode = getProductOptionInfo(opt).color_code;
    opt.style["background-color"] = colorCode;
    

    // click event
    opt.addEventListener("click", ()=>{
      if (currentSelectOption) {
        uncheckProductOption(currentSelectOption);
      }
      checkProductOption(opt);
    });
  })
}

function uncheckProductOption(option) {
  const check = getProductOptionCheck(option);
  check.style.display = "none";

  currentSelectOption = null;
  clearSizeInfo();
}

function checkProductOption(option) {
  const check = getProductOptionCheck(option);
  check.style.display = "block";

  currentSelectOption = option;

  const optionInfo = getProductOptionInfo(option) 
  setSizeInfo(optionInfo.sizes);
  setVgId(optionInfo.vg_id);
}

function setVgId(vgId) {
  const vgIdInput = document.querySelector("#v_id");
  vgIdInput.value = vgId;
}


function getProductOptionCheck(option) {
  return option.querySelector(".js-product-select-option-check");
}

function getSizeBox() {
  return document.querySelector("#js-product-size-box");
}

function clearSizeInfo() {
  const sizeBox = getSizeBox()
  sizeBox.innerHTML = "";
}

function getProductOptionInfo(option){
  const { productOption } = option.dataset;
  console.log(productOption);
  return JSON.parse(productOption);
}

function setSizeInfo(sizes) {
  clearSizeInfo();
  const sizeBox = getSizeBox();

  for (let i = 0; i < sizes.length; i++) {
    const sizeEl = document.createElement("span");
    sizeEl.classList.add("mk-product__size");
    sizeEl.innerHTML = sizes[i];
    sizeBox.appendChild(sizeEl);

    if(i != sizes.length - 1) {
      const dividerEl = document.createElement("span");
      dividerEl.classList.add("mk-product__size-divider");
      sizeBox.appendChild(dividerEl);
    }
  }
}
