<template>
  <div class="modal fade" ref="payModalRef" id="shopCarModal">
    <div class="modal-dialog modal-xl modal-fullscreen-md-down">
      <div class="modal-content pay-modal">
        <div class="modal-header pay-modal-header">
          <button v-if="personalData.step !== '3'" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pay-modal-body px-sm-5">
          <p class="fs-1">訂購只需要三步驟</p>
          <!-- Progress bar -->
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-9">
                <div class="progressbar">
                  <div class="progressbar__progress" id="progress" ></div>
                  <div v-for="(step, index) in progressSteps"
                    :key="index"
                    class="progressbar__step"
                    :class="step.active ? 'progressbar__step--active' : ''"
                    :data-title="step.title"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- form -->
          <Form :validationSchema="validationSchema" @submit="storeAndChangeStepTo('2', 'verify')" ref="form">

            <StepPaymentType class="form-step" :class="formStepClass.stepPaymentType"
              :personalData="personalData" :paymentOptions="paymentOptions" :shippingOptions="shippingOptions" :landingSale="landingSale"
              @clearCVSdata="clearCVSdata" @useCoupon="useCoupon"
            />

            <StepRecipient class="form-step" :class="formStepClass.stepRecipient"
              :personalData="personalData" :cities="cities"
              @storeAndChangeStepTo="storeAndChangeStepTo"
            />

            <StepOrderCreated v-if="personalData.step == '3'" class="form-step" :class="formStepClass.stepOrderCreated"
              :personalData="personalData" :landingSale="landingSale"
              @storeAndChangeStepTo="storeAndChangeStepTo"
            />
            
            <!-- step button -->
            <div class="pay-modal-footer">
              <p v-if="personalData.step != 3" class="total-amount">
                總計&nbsp;
                <span>&nbsp;NT$&nbsp;{{ personalData.totalAmount }}&nbsp;</span>
                <span v-if="personalData.couponCode && personalData.couponCode !== ''" class="coupon-discount">已折扣NT${{couponDiscount}}</span>
              </p>
              <div>
                <button v-if="personalData.step == 1" @click="storeAndChangeStepTo('2')" type="button" class="mk-btn pay-modal-step-btn me-1">下一步</button>
                <button v-if="personalData.step == 2" @click="storeAndChangeStepTo('1')" type="button" class="mk-btn pay-modal-step-btn me-1">上一步</button>
                <button v-if="personalData.step == 2" type="submit" class="mk-btn pay-modal-step-btn me-1">下一步</button>
              </div>
            </div>
          </Form>

          <!--ECPay -->
          <form id="ecpay-map" :action="csvMap.url" method="post" :hidden="false">
            <input type="text" v-for="(value, key) in csvMap.data" :key="key" :placeholder="key" :name="key" :value="value" class="form-control d-none">
          </form>

          <form id="ecpay-info" :action="ecpay.url" method="post" :hidden="false" class="d-none">
            <input type="text" v-for="(value, key) in ecpay.data" :key="key" :placeholder="key" :name="key" :value="value" class="form-control">
            <input v-if="ecpay.data" type="submit" value="提交至綠界付款">
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, reactive, computed, watch, watchEffect, defineProps, defineEmits, onMounted} from "vue"
  import { Modal } from "bootstrap";
  import axios from 'axios';
  import StepPaymentType from "./StepPaymentType.vue";
  import StepRecipient from "./StepRecipient.vue";
  import StepOrderCreated from "./StepOrderCreated.vue";
  import { HandleErrorResponse } from "~/javascripts/utils.js";
  import swal from "sweetalert";

  // 驗證測試yup系統化
  import { Form } from 'vee-validate';
  import * as yup from 'yup';

  const props = defineProps({
    checkOutForm: { // 跳至特定step時會塞的資料
      type: Object,
      default: () => {}
    },
    cities: {
      type: Array,
      default: () => []
    },
    order_amount: {
      type: Number,
      default: 0
    },
    paymentOptions: {
      type: Array,
      default: () => []
    },
    shippingOptions: {
      type: Array,
      default: () => []
    },
    landingSale: {
      type: Object,
      default: () => {
        return {
          isVisitor: false,
          slug: '',
        }
      }
    },
  });

  // ECPay綠界相關
  const csvMap = reactive({ url: "", data: null });
  const ecpay = reactive({ url: "", data: null });

  const personalData = reactive({
    step: "0",
    paymentType: "ATM", //付費方式
    shippingType: "HOME", // 運送方式
    orderAmount: 0, // 小計
    totalAmount: 0, // 總計
    name: "",
    cellphone: "", // 手機
    cityID: "", //縣市
    districtID: "", //區域
    address: "", //街道地址
    email: "",
    companyTitle: "",
    taxIDnumber: "", // 統編
    customerNote: "", //備註
    CVSStoreID: "",
    CVSStoreName: "",
    CVSAddress: "",
    CVSOutSide: "",
    couponCode: "",
  });

  const progressSteps = reactive([
    {title: "選擇付款方式", active: false},
    {title: "填寫收件人資料", active: false},
    {title: "完成訂購", active: false},
  ]);

  const formStepClass = computed(() => {
    return {
      stepPaymentType: personalData.step == '1' ? "form-step-active" : "",
      stepRecipient: personalData.step == '2' ? "form-step-active" : "",
      stepOrderCreated: personalData.step == '3' ? "form-step-active" : "",
    }
  })

  const validationSchema = computed(() => {
    const baseSchema = {
      name: yup.string()
        .required('請填寫姓名')
        .test(value => {
          const chineseRegex = /^[\u4E00-\u9FA5]{2,5}$/;
          const englishRegex = /^(?=[A-Za-z\s]{4,10}$)[A-Za-z]+(?:\s[A-Za-z]+)*$/;
          return chineseRegex.test(value) && !/\s/.test(value) || englishRegex.test(value);
        }),

      cellphone: ["FAMIC2C", "UNIMARTC2C", "HOME"].includes(personalData.shippingType) ? yup.string().required().matches(/^09\d{2}-?\d{3}-?\d{3}$/) : yup.string().required().matches(/^\+?\d{1,14}$/),
      // yup.string().required().matches(/^\+?[1-9]\d{1,14}$/) // if限制第一個數1~9

      email: yup.string().required('請填寫email').email(),
      // companyTitle: yup.string(),
      // taxIDnumber: yup.string(),
      // note: yup.string(),
    };

    if (["FAMIC2C", "UNIMARTC2C"].includes(personalData.shippingType)) {
      // 如果在超商步驟，加入超商相關驗證
      baseSchema.superMarket = yup.string().required();
    } else if (["HOME"].includes(personalData.shippingType)){
      // 如果在宅配步驟，加入宅配相關驗證
      baseSchema.city = yup.string().required();
      baseSchema.area = yup.string().required();
      baseSchema.address = yup.string().required();
    } else {
      // 如果在海外郵寄步驟，加入海外相關驗證
      baseSchema.address = yup.string().required();
    }

    return yup.object(baseSchema);
  });

  const cellphoneFormatFlag = computed(() => ["FAMIC2C", "UNIMARTC2C", "HOME"].includes(personalData.shippingType))

  const couponDiscount = ref(0)

  watch(() => props.order_amount, (newV)=>{
    personalData.orderAmount  = newV ?? 0;
  })

  watchEffect(() => {
    // set data from props
    if (props.checkOutForm && Object.keys(props.checkOutForm).length > 0) {
      personalData.paymentType  = props.checkOutForm.payment_type;
      personalData.shippingType = props.checkOutForm.shipping_type;
      personalData.name         = props.checkOutForm.recipient ?? gon.u_name;
      personalData.cellphone    = props.checkOutForm.recipient_cellphone ?? gon.u_cell_phone;
      personalData.cityID       = props.checkOutForm.city_id; // 縣市
      personalData.districtID   = props.checkOutForm.district_id; // 鄉鎮
      personalData.address      = props.checkOutForm.recipient_address;
      personalData.email        = props.checkOutForm.recipient_email ?? gon.u_email;
      personalData.companyTitle = props.checkOutForm.company_name;
      personalData.taxIDnumber  = props.checkOutForm.company_identifier;
      personalData.customerNote = props.checkOutForm.customer_note;
      // --超商
      personalData.CVSStoreID   = props.checkOutForm.CVSStoreID;
      personalData.CVSStoreName = props.checkOutForm.CVSStoreName;
      personalData.CVSAddress   = props.checkOutForm.CVSAddress;
      personalData.CVSOutSide   = props.checkOutForm.CVSOutSide;
      personalData.couponCode   = props.checkOutForm.coupon_code;
    }
  })

  watchEffect(() => {
    // 計算總價
    const selectedPaymentType = personalData.paymentType;
    const selectedShippingType = personalData.shippingType;

    const orderAmount = personalData.orderAmount;
    const paymentExtraAmount = props.paymentOptions?.find(option=>option.code == selectedPaymentType)?.price || 0;
    const shippingExtraAmount = props.shippingOptions?.find(option=>option.code == selectedShippingType)?.price || 0;

    personalData.totalAmount = orderAmount + paymentExtraAmount + shippingExtraAmount - couponDiscount.value
  })

  watch(cellphoneFormatFlag ,() => {
    personalData.cellphone = '';
  })

  function clearCVSdata(){
    personalData.CVSStoreID = '';
    personalData.CVSStoreName = '';
    personalData.CVSAddress = '';
    personalData.CVSOutSide = '';
  }

  function useCoupon(couponObj) {
    personalData.couponCode = couponObj.code;
    couponDiscount.value = couponObj.discount;
  }

  watch(() => personalData.step, newStep => {
    let currentStep = Number(newStep);
    progressSteps[0].active = currentStep >= 1;
    progressSteps[1].active = currentStep >= 2;
    progressSteps[2].active = currentStep >= 3;
  })

  function storeAndChangeStepTo(nextStep, specialTarget = "") {
    // specialTarget => ["verify", "goMap"]
    let apiUrl = '/api/carts/check_out';
    let apiParams = {};

    if (specialTarget == 'close') apiParams = {step: '0'}; // close model
    else apiParams = handleCheckOutParams(personalData.step, specialTarget);

    // ladingSale為浮動uri
    if (props.landingSale.isVisitor) {
      const slug = props.landingSale.slug;
      apiUrl = `${slug}/checkout`;
    }

    axios.post(apiUrl, apiParams)
    .then(response => {
      const checkOutData = response.data.data;

      // set ecpay form data
      if (specialTarget == "verify") {
        ecpay.data = checkOutData.data;
        ecpay.url = checkOutData.url;
      } else if (specialTarget == "goMap") {
        csvMap.data = checkOutData.data;
        csvMap.url = checkOutData.url;
      } else {
        csvMap.data = {};
        csvMap.url = "";
        ecpay.data = {};
        ecpay.url = "";
      }
      
      // change step
      personalData.step = nextStep;
    })
    .then(() => {
      if (specialTarget == "verify") {
        const form = document.getElementById('ecpay-info');
        if (form) form.submit();
      } else if (specialTarget == "goMap") {
        const form = document.getElementById('ecpay-map');
        if (form) form.submit();
      }
    })
    .catch(error => {
      const errorMsg = HandleErrorResponse(error, () => {
        swal({
          title: "提醒",
          text: '產品資訊調整，請重新選購',
          dangerMode: true,
        }).then((_) => {
          window.location.reload();
        });
      });
      // TODO: 最對應處理
      switch(errorMsg){
        case "unknown coupon code":
          break;
        case "error payment type":
          break;
        case "error shipping type":
          break;
        case "cvs map is blank":
          break;
        case "order_amount not match":
          break;
        case "total_amount not match":
          break;
        case "design or line items not exist":
          break;
        default:
          break;
      }
    })
  }

  function handleCheckOutParams(currentStep, specialTarget = "") {
    let params = {}

    switch (currentStep) {
      case "1":
        Object.assign(params, {
          "step": personalData.step,
          "shipping_type": personalData.shippingType,
          "payment_type": personalData.paymentType,
          "order_amount": personalData.orderAmount,
          "total_amount": personalData.totalAmount,
          "coupon_code" : personalData.couponCode,
        })
        break;
      case "2":
        Object.assign(params, {
          "step": personalData.step,
          "recipient": personalData.name,
          "recipient_cellphone": personalData.cellphone,
          "recipient_email": personalData.email,
          "company_name": personalData.companyTitle,
          "company_identifier": personalData.taxIDnumber,
          "customer_note": personalData.customerNote,
        })

        // 不同shippingType傳布同格式 TODO
        if (["UNIMARTC2C"].includes(personalData.shippingType)) {
          Object.assign(params, {
            "CVSStoreID": personalData.CVSStoreID,
            "CVSStoreName": personalData.CVSStoreName,
            "CVSAddress": personalData.CVSAddress,
            "CVSOutSide": personalData.CVSOutSide,
          })
        } if (["FAMIC2C"].includes(personalData.shippingType)) {
          Object.assign(params, {
            "CVSStoreID": personalData.CVSStoreID,
            "CVSStoreName": personalData.CVSStoreName,
            "CVSAddress": personalData.CVSAddress,
            "CVSOutSide": personalData.CVSOutSide,
          })
        } else {
          Object.assign(params, {
            "city_id": personalData.cityID, // 縣市
            "district_id": personalData.districtID, // 鄉鎮
            "recipient_address": personalData.address,
          })
        }

        // 特殊事件
        if (specialTarget == "verify") Object.assign(params, { "next": "verify_order" }) // 進入金流
        else if (specialTarget == "goMap") Object.assign(params, { "next": "to_map" }) // 打開超商地圖
        else if (specialTarget == "close") Object.assign(params, { "next": "close" }) // 關閉modal

        break;
      case "3":
        Object.assign(params, {
          "step": personalData.step
        })
        break;
    }

    return params
  }

  

  // Modal控制
  let payModalRef = ref();
  let payModal = null;
  function closeModal() {
    storeAndChangeStepTo('0', 'close')
  }
  function _hide() {
    payModal.hide()
  }
  function _show() {
    payModal.show()
  }
  function _showTargetStep(target) {
    // target 跳頁
    const stepNum = Number(target);
    if (stepNum > 0 && stepNum <= 3) personalData.step = String(stepNum);

    payModal.show();
  }

  defineExpose({
    showTargetStep: _showTargetStep,
    show: _show,
    hide: _hide,
  });

  onMounted(() => {
    payModal = new Modal(payModalRef.value);
    document.querySelector("#shopCarModal").addEventListener("hidden.bs.modal", () => {
      closeModal()
    })
    
  })
</script>


<style lang="scss" scoped>
  :deep .markUp {
    display: inline-block;
    margin-left: 0.5rem;
    padding: 2px 8px;
    color: #ffffff;
    background-color: #EE7C26;
    border-radius: 0.175rem;
    line-height: 1;
    font-size: 0.8rem;
    vertical-align: 1px;
    @media screen and (max-width:991px ){
      border-radius: 2rem;
    }
  }
  :deep .form-check-label {
    display: inline-block;
    line-height: 1.375rem;
  }
  
  .pay-modal{
    margin:0 auto;
    border-radius: 0;
    max-width: 1080px;
    min-height: 680px;
    
    .pay-modal-step-btn{
      padding: 0.175rem 1.75rem;
      border-radius: 0.8rem;
      font-size: 1.25rem;
    }
  }
  .pay-modal-header{
    border-bottom: 0px solid;
    button{
      font-size: 1rem;
      float: right;
    }
  }
  .pay-modal-body{
    background-color: #fff;
    > p:first-child{
      text-align: center;
    }
    /* Progressbar */
    .progressbar{
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 2rem 0 6rem;
      padding: 0 30px;
      max-width: 712px;
      &::before,
      &__progress{
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 4px;
        background-color: #dadada;
        width: calc(100% - 60px);
      }
      &__progress{
        background-color: #EE7C26;
        width: 0px;
      }
      &__step{
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: #dadada;
        border-radius: 50%;
        border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
      }
      &__step::before{
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='white' d='M13.354 4.646a.5.5 0 0 0-.708 0L6.5 10.793l-2.646-2.647a.5.5 0 0 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7a.5.5 0 0 0 0-.708z'/%3E%3C/svg%3E"); /* "✓" */
        width: 24px;
        height: 24px;
        /* counter-increment: step;
        content: counter(step); */
      }
      &__step::after{
        content: attr(data-title);
        position: absolute;
        top: calc(100% + 1.25rem);
        width: 140px;
        text-align: center;
        font-size: 1.05rem;
        color: #666;
      }
      &__step--active{
        background-color: #EE7C26;
        color: #fff;
      }
    }
  
  }
  .pay-modal-footer{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 2.25rem;
    p{
      margin-right: 1.25rem;
      font-size: 1.25rem;
      span{
       
        vertical-align: text-top;
        font-size: 1.75rem;
        font-weight: 900;
      }
    }
    .total-amount{
      position: relative;
    }
    .coupon-discount{
      position: absolute;
      display: inline-block;
      bottom: -1rem;
      right: 0.5rem;
      color: #EE7C26;
      font-size: 1rem;
      font-weight: normal;

    }
  }

  @media screen and (max-width:991px ){
    
    .pay-modal{      
      min-height: auto;
      .pay-modal-step-btn{
        padding: 0.175rem 1.35rem;
        border-radius: 2rem;
        font-size: 1rem;
      }
    }
    .pay-modal-header{
      padding-bottom: 0;
    }
    .pay-modal-body{
      padding-top:0;
      
      > p:first-child{
        text-align: start;
        color: rgba(37, 37, 37, 1)
      }
      .progressbar{
        margin: 1.25rem 0 4rem;
        &__step{
          width: 24px;
          height: 24px;
        }
        &__step::before{
          width: 16px;
          height: 16px;
        }
        &__step::after{
          top: calc(100% + 1rem);
          font-size: 0.9rem;
        }
      }
    }

    .pay-modal-footer{
      justify-content: space-between;
      margin-top: 1.25rem;
      
      p{
        font-size: 1rem;
        margin-right: 0;
        span{
          vertical-align: text-bottom;
          font-size: 1.25rem;
        }
      }
      .coupon-discount{
        font-size: 12px;
      }
    }
    

  }

  @media screen and (max-width:767px ){
    .pay-modal-body{
      padding-bottom: 80px;
    }
    .pay-modal-footer{
      background-color: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      padding:0 3rem;
      height: 60px;
      border-top:1px solid rgb(217,217,217) ;
    }

  }

  @media screen and (max-width:576px ){
    .pay-modal-footer{
      padding: 0 1rem;
    }
  }
  
  .form-step {
    display: none;
  }

  .form-step-active {
    display: block;
  }

</style>
