<template>
  <div class="view-product-content">
    <!-- 產品照片 -->
    <div id="product-carousel" class="carousel mk-carousel slide">
      <!-- 主要圖片顯示區 -->
      <div class="carousel-inner">
        <div v-for="(url, index) in product.image_urls" :key="`m${index}`" :class="{ 'active': index == 0 }"
          class="carousel-item">
          <div class="mk-carousel__image">
            <div class="mk-image-box">
              <img :src="url" loading="lazy" v-if="url">
              <img src="https://placehold.co/200" loading="lazy" v-else>
            </div>
          </div>
        </div>
      </div>

      <!-- 顯示前一個圖片的按鍵 -->
      <button type="button" class="carousel-control-prev" data-bs-target="#product-carousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </button>

      <!-- 顯示後一個圖片的按鍵 -->
      <button type="button" class="carousel-control-next" data-bs-target="#product-carousel" data-bs-slide="next">
        <span class="carousel-control-next-icon"></span>
      </button>

      <!-- 預覽圖片 -->
      <div class="carousel-indicators">
        <div class="carousel-indicators-inner">
          <button type="button" data-bs-target="#product-carousel" :data-bs-slide-to="index"
            v-for="(url, index) in product.image_urls" :key="`p${index}`" :class="{ 'active': index == 0 }">
            <img :src="url" loading="lazy" v-if="url">
            <img src="https://placehold.co/80" loading="lazy" v-else>
          </button>
        </div>
      </div>
    </div>
    <hr/>

    <!-- 產品面板  -->
    <div class="product-panel px-3">
      <div class="product-panel__name">{{ product.name }}</div>
      <div class="product-panel__price">{{ formatCurrency(product.selling_price,'decimal') }}元</div>
      <div class="product-panel__color">顏色 <span class="badge ms-2" :style="colorBadgeStyle()">{{ productColorName }}</span> </div>
      <!-- size -->
      <CounterGroup :line_items="line_items" @IncrementItem="IncrementItem" @DecrementItem="DecrementItem" 
      />
    </div>
    <hr/>
    
    <!-- 產品詳情 -->
    <div class="product-detail-btn px-3" role="button" @click="showDetail = !showDetail">
      產品訊息
      <i v-if="!showDetail" class="bi bi-chevron-down" ></i>
      <i v-if="showDetail" class="bi bi-chevron-up"></i>
    </div>
    <hr/>
    <ul v-show="showDetail" class="product-detail px-3">
      <li>貨號&#xff1a;{{ product.sku }}</li>
      <li>品牌&#xff1a;MOK</li>
      <div v-html="product.desc"></div>
    </ul>
    <hr/>

    <!-- 加入購物車 -->
    <div class="product-summary-block px-3 ">
      <span style="line-height: 28px;">小計</span>
      <div> 
        <span class="product-summary-block__price fw-6">NT{{ formatCurrency(modalTotal,'currency') }}</span> <!--data-bs-dismiss="modal"-->
        <span role="button" 
          :class="[!atLeastOne ? 'disable' : '', 'product-summary-block__btn mk-btn js-add-to-shopping-cart']"
          @click="addToShoppingCart"
          data-bs-dismiss="modal"
          :disabled="!atLeastOne"
          >加入購物車
        </span> 
      </div>
    </div>
  </div>

</template>


<script setup>
import { GetImageUrl } from "~/javascripts/utils.js";
import CounterGroup from "./CounterGroup.vue";
import { ref, defineProps, defineEmits, computed, reactive, watch, onMounted } from 'vue'
import { useLandingSalseStore } from './landing_sales_store.js'
import { Modal } from "bootstrap";
  const props = defineProps({
    slug: String
  });

  const store = useLandingSalseStore();
  const storeProductID = ref(null)

  const showDetail = ref(false);
  const product = ref({});
  const productColorName = computed(()=> product.value.color ? product.value.color.name : '')
  const line_items = ref([]);
  const modalTotal = ref(0);

  const atLeastOne = computed(()=>{
    for (const el of line_items.value){
      if(el.quantity != 0 ) return true;
    }
    return false;
  });
  
  onMounted(() => {
    store.api = `${props.slug}`

    const modal = new Modal(document.querySelector('.js-view-product-modal'));
    const btns = document.querySelectorAll('.js-view-product-detail-btn')
    btns.forEach(btn => {
      btn.addEventListener('click', async () => {
        storeProductID.value = btn.getAttribute('data-bs-store-product-id');
        if(storeProductID.value){
          try {
            await getProduct(storeProductID.value);
            modal.show();
          } catch (err) {
            console.log(err);
          }
        }
      })
    })
   
  });

  const getProduct = async (id)=>{
    store.productID = id;
    try {
      const data = await store.getProductInfo();
      product.value = data.product;
      setLineItems();
    } catch (err) {
      throw err;
    }
  } ;

  const setLineItems = () => {
    modalTotal.value = 0;
    line_items.value = []
    product.value.size_spreads_info.forEach(info => {
      line_items.value.push({
        size: info.name,
        quantity: 0,
        surcharge: info.surcharge,
        sub_total: 0,
      })
    });
  } 

  const IncrementItem = (item) => {
    item.quantity++
    modalTotal.value = calculatePrice();
  }
  const DecrementItem = (item) => {
    if (item.quantity >= 1) {
      item.quantity--
    }
    modalTotal.value = calculatePrice();
  }
  const calculatePrice = () =>{
    let price = 0;
    line_items.value.forEach(item => {
      item.sub_total = (product.value.selling_price + item.surcharge) * item.quantity;
      price += item.sub_total;
    });
    return price;
  }

  const formatCurrency = (value,format) => {
    // 检查value是否是数字
    if (isNaN(value)) {
      return value; // 如果不是数字，直接返回原始值
    }
    // 使用toLocaleString()方法将数字格式轉化
    return value.toLocaleString('zh-TW', { style: format, currency: 'TWD',minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const colorBadgeStyle = () => {
    // TODO 整合到scss
    const isWhiteColor = product.value.color?.code === "#ffffff";
    return {
      color: isWhiteColor ? "#666666" : "#ffffff",
      border: isWhiteColor ? "1px solid #d1d1d1" : "none",
      "background-color": product.value.color?.code || '',
    };
  }

  const successionInfo = () => {
    // 已加入購物車modal(頁面中心)
    const successionModal = document.querySelector('.js-succession-modal');
    successionModal.classList.add("fadeOutNotification")
    setTimeout(() => successionModal.classList.remove("fadeOutNotification"), 2500);
    
    // 已加入購物車tips(左下tooltips)
    if(window.getComputedStyle(document.getElementById('floating-icons')).display !== 'none'){
      const successionTips = document.querySelector('.js-succession-tips');
      successionTips.classList.add("fadeOutNotification")
      setTimeout(() => successionTips.classList.remove("fadeOutNotification"), 2500);
    }
  }

  const  addToShoppingCart = async(event)=>{
    const params = [];
    line_items.value.forEach(item => {
      if(item.quantity != 0) {
        const obj = {
          size: item.size,
          quantity: item.quantity,
        }
        params.push(obj)
      }
    });
    
    if(params.length != 0) {
      try {
        await store.setShoppingCart(storeProductID.value, params);
        successionInfo();
      } catch (err) {
        console.log(err)
      }
    } 
  }

</script>

<style lang="scss" scoped>
:deep .markUp{
  padding: 2px 8px;
  color: #ffffff;
  background-color: #EE7C26;
  border-radius: 0.175rem;
}
.product-panel{
  &__name{
    margin-bottom:0.75rem;
    font-size: 1.5rem;
    font-weight: 900;
  }
  &__price{
    margin-bottom:0.75rem;
    color: rgba(249, 72, 22, 1);
    font-size: 1.25rem;
    font-weight: 900;
  }
  &__color{
    margin-bottom:1rem;
    .markUp {
      margin-left: 0.75rem ;
      font-size: 0.8rem;
    }
  }
}
.product-detail-btn{
  display: flex;
  justify-content: space-between;
}
:deep .product-detail{
  li{
    list-style-type:disc;
    margin: 1rem 0rem 1rem 1.5rem;
  }
}
.product-summary-block{
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  &__price{
    display:inline-block;
    height:auto; 
    line-height: 1;
    font-weight: 900;
  }
  &__btn{
    margin-left:20px ;
    padding: 0.25rem 2rem;
    border-radius: 20px;
  }
  &__btn.disable{
    opacity: 0.7;
    &:active{
      background-color: #EE7C26;
    }
    &:hover{
      background-color: #EE7C26;
    }
  }
}

.view-product-content {
  .carousel {
    .mk-carousel__image {
      width: 100%;
      height: auto;
    }
    .carousel-control-prev {
      width: 40px;
    }
    .carousel-control-next {
      width: 40px;
    }
    .carousel-indicators {
      margin: 0;
      padding: .5rem 0;
    }
  }
}
</style>
