export default function InitUserOrderPage() {
  const pageID = "user-orders-list" 
  const page   = document.getElementById(pageID)
  if (!page) return false;

  document.addEventListener("DOMContentLoaded", () => {
    initOrderSearchResetIcon();
    initMobileOrderSearchResetIcon();
    initBackButton();
  });
}

function initOrderSearchResetIcon() {
  const resetIcon = document.getElementById("js-order-search-reset");
  const input     = document.getElementById("js-order-search-input");

  const showResetIcon = ()=> {
    resetIcon.style.display = "block";
  }

  const hideResetIcon = ()=> {
    resetIcon.style.display = "none";
  }

  input.addEventListener("input", ()=>{
    if(input.value !== "") {
      showResetIcon();
    } else {
      hideResetIcon();
    }
  });

  resetIcon.addEventListener("click", ()=>{
    input.value = "";
    input.dispatchEvent(new Event("input"));
  });

  input.dispatchEvent(new Event("input"));
}

function initMobileOrderSearchResetIcon() {
  const resetIcon = document.querySelector(".mobile #js-order-search-reset");
  const input     = document.querySelector(".mobile #js-order-search-input");

  const showResetIcon = ()=> {
    resetIcon.style.display = "block";
  }

  const hideResetIcon = ()=> {
    resetIcon.style.display = "none";
  }

  input.addEventListener("input", ()=>{
    if(input.value !== "") {
      showResetIcon();
    } else {
      hideResetIcon();
    }
  });

  resetIcon.addEventListener("click", ()=>{
    input.value = "";
    input.dispatchEvent(new Event("input"));
  });

  input.dispatchEvent(new Event("input"));
}

function initBackButton() {
  const btn = document.getElementById("js-order-back");

  btn.addEventListener("click", ()=>{
    window.history.go(-1);
    return false;
  });
}