import { defineStore } from 'pinia'
import axios from 'axios';
import {toastr_sucess, toastr_error} from '~/javascripts/toastr.js';
import swal from "sweetalert";

export const useLandingSalseStore = defineStore('resourceFormStore', {
  state: () => {
    return {
      resource:  {},
      api:       null,
      productID: '',
      data:{
        items: [],
        total_price: 0,
      },
    }
  },
  getters: {
    getShoppingCart() {
      return this.data;
    },
  },
  actions: {
    // API
    async initShoppingCartInfo() {
      let resp = {}
      try {
        resp = await axios.get(`${this.api}/cart`, {headers: { 'Cache-Control': 'no-cache' }}); // 需要禁止cache，否則step 3 按上一步會出錯
        this.data = resp.data.data;
      } catch(err) {
        if(err.response && err.response.status === 422) this.reloadCart(); 
        throw err;
      }
    },
    
    async getProductInfo() {
      let resp = {};
      if(this.productID) {
        try {
          resp = await axios.get(`${this.api}/products/${this.productID}`);
          resp.data.product.id = this.productID;
          return resp.data;
        } catch (err) {
          if(err.response && err.response.status === 422) this.reloadCart(); 
          throw err;
        }
      }
    },

    async setShoppingCart(store_product_id, line_items){
      let resp = {}
      const params = {
        store_product_id,
        line_items
      };
      try {
        resp = await axios.post(`${this.api}/add_cart`, params);
        this.data = resp.data.data;
      } catch (err) {
        if(err.response && err.response.status === 422) this.reloadCart(); 
        throw err;
      }
     
    },

    async updateShoppingCart(item, quantity, type=''){
      let resp = {}
      const params = {
        data:{
          "update_info": {
            type,
            line_item_id: item.line_item_id,
            variant_id: item.variant_id,
            quantity,
          }
        }
      }
      try {
        resp = await axios.post(`${this.api}/change_cart`, params);
        this.data = resp.data.data;
      } catch (err) {
        if(err.response && err.response.status === 422) this.reloadCart(); 
        throw err;
      }
    },

    async landingCheckout(){
      try {
        const response = await axios.post(`${this.api}/checkout`, { "step": "0" });
      } catch (err) {
        if(err.response && err.response.status === 422) this.reloadCart(); 
        throw err;
      }
    },

    reloadCart() {
      swal({
        title: "提醒",
        text: '產品資訊調整，請重新選購',
        dangerMode: true,
      }).then((_) => {
        window.location.reload();
      });
    },

    processAPICatchError(e) {
      if (e.response && e.response.status && e.response.status !== 422) {
        toastr_error("請聯絡管理人員(errcode 01)");
      } else if (e.response && e.response.data && e.response.data.message) {
        toastr_error(e.response.data.message);
      } else {
        toastr_error("請聯絡管理人員(errcode 02)");
      }
    },
  }
})