import '~/scss/app.scss'
import '~/javascripts/app/pages/index.js';
import App from '~/javascripts/app/index.js'
import {InitValidationForms} from '~/javascripts/validation_form.js';
import SubMenu from '~/javascripts/app/sub_menu.js';
import EyePasswordInput from '~/javascripts/app/input/eye_password_input.js';
import Flash from '~/javascripts/flash.js';
import {NewToolTipTags} from '~/javascripts/tooltip.js';
import NewSharedLinks from '~/javascripts/app/shared_link.js';
import NewSearchReset from '~/javascripts/app/search_reset.js'
import NewDropdown from '~/javascripts/app/dropdown.js';
import NewDropdownSelect from '../javascripts/dropdown_select.js';
import InitCheckout from '~/javascripts/app/checkout/index.js';
import InitProductCategoryPage from '../javascripts/app/pages/product_categories/index.js';
import InitProductPage from '../javascripts/app/pages/products/index.js';
import InitLandingSalesPage from '../javascripts/app/pages/landing_sales/index.js';
import InitUserOrderPage from '../javascripts/app/pages/users/orders/index.js';
import InitUserStoreIndexPage from '../javascripts/app/pages/users/stores/index.js';
import InitUserStoreEditPage from '../javascripts/app/pages/users/stores/edit.js';
import InitUserStoreStorePagePage from '../javascripts/app/pages/users/stores/store_page.js';
import InitUserStoreProducts from '../javascripts/app/pages/users/store_products/index.js';
import InitUserStoreProductsForm from '../javascripts/app/pages/users/store_products/form.js';
import InitUserStorePerformace from '~/javascripts/app/pages/users/stores/store_performace.js';;
import InitUserCouponForm from '~/javascripts/app/pages/users/coupons/form.js';
import InitUserWalletPage from '~/javascripts/app/pages/users/wallets/index.js'
import TextareaApapt from '~/javascripts/app/users_form/textarea_adapt.js';
// import {SetToastrAlert} from '~/javascripts/toastr.js';
import {findToastrMessage} from '~/javascripts/toastr.js';
import NewFlatPickr from '~/javascripts/flatpickr.js';

new App();
InitValidationForms();
new EyePasswordInput();
new SubMenu();
new Flash();
new NewSharedLinks();
new NewSearchReset();
new NewDropdown();
new NewDropdownSelect();

// SetToastrAlert()
findToastrMessage();

NewToolTipTags();

// checkout
InitCheckout();

InitProductCategoryPage();
InitProductPage();
InitLandingSalesPage();
InitUserOrderPage();

//store
InitUserStoreIndexPage();
InitUserStoreEditPage();
InitUserStoreStorePagePage();
// store_performace
InitUserStorePerformace();
//store_product
InitUserStoreProducts();// TODO:重構
InitUserStoreProductsForm();//TODO:重構
// coupon
InitUserCouponForm();
// wallet
InitUserWalletPage();

//TODO 整合成users form專用的一個js,目前vue.js也使用TextareaApapt()，需寫在最後
// users form
TextareaApapt();
NewFlatPickr();

