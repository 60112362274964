import { createApp, h } from 'vue'
import ViewProductModalContent from './view_product_modal_content.vue'
import LandingShoppingCart from './landing_shopping_cart.vue'
import { createPinia } from 'pinia'
import {zoomImageAnimationHandler} from '~/javascripts/app/zoom_in_image.js';


export default function InitLandingSales() {
  const pageID = "landing-sales" 
  const page   = document.getElementById(pageID)
  if (!page) return false;

  document.addEventListener("DOMContentLoaded", () => {
    initNotifyBtn();
    initDisplayModeBtn(); // display mode
    initFloatingIconsBtn();
    initZoomimage();
    // initViewProductModalBtn(vueAppInstance); // vue productID
  });

  const pinia = createPinia();
  const url = document.getElementById('landing-sales').getAttribute('data-slug-url')

  // view product modal content(精選單品選購modal)
  const viewProductModalContentSelector = "#js-view-product-modal-content"
  
  if (!document.querySelector(viewProductModalContentSelector)) return false;
  const viewProductModalContentApp = createApp(ViewProductModalContent, {slug: url});
  // 傳遞props的方式
  // const viewProductModalContentApp = createApp({
  //   data() {
  //     return {
  //       productID: null // 初始化 productId 属性为 null
  //     }
  //   },
  //   render() {
  //     return h(ViewProductModalContent, { productID: this.productID }) // 将 productId 传递给子组件
  //   }
  // });

  viewProductModalContentApp.use(pinia);
  // const vueAppInstance = viewProductModalContentApp.mount(viewProductModalContentSelector);
  viewProductModalContentApp.mount(viewProductModalContentSelector);

  // landing shopping cart (目前選購區塊)
  const landingShoppingCartSelector = "#js-landing-shopping-cart";
  if (!document.querySelector(landingShoppingCartSelector)) return false;
  const landingShoppingCartApp = createApp(LandingShoppingCart,{slug: url});
  landingShoppingCartApp.use(pinia)
  landingShoppingCartApp.mount(landingShoppingCartSelector);
  
};

// 關閉notify
function initNotifyBtn(){
  const closeBtn = document.querySelector('.js-close-notify-btn');
  if(closeBtn) {
    closeBtn.onclick = () => {
      closeBtn.parentNode.style = "display:none";
    }
  }
}

 //切換顯示模式
function initDisplayModeBtn(){
  const listModeBtn = document.getElementById("js-list-mode-btn");
  const gridModeBtn = document.getElementById("js-grid-mode-btn");
  const featuredBlockList = document.getElementById("js-featured-block__list")
  if(listModeBtn){
    listModeBtn.addEventListener('click',()=>{
      listModeBtn.classList.add('active')
      gridModeBtn.classList.remove('active')
      featuredBlockList.classList.add('list-mode')
      featuredBlockList.classList.remove('grid-mode')
    });
  }
  
  if(gridModeBtn){
    gridModeBtn.addEventListener('click',()=>{
      gridModeBtn.classList.add('active')
      listModeBtn.classList.remove('active')
      featuredBlockList.classList.add('grid-mode')
      featuredBlockList.classList.remove('list-mode')
    })
  }
}

// floating_icons
function initFloatingIconsBtn(){
  const checkoutImmediatelyBtn = document.querySelector('.js-checkout-immediately');
  if(checkoutImmediatelyBtn) {
    checkoutImmediatelyBtn.onclick = () => {
      const shoppingCart = document.getElementById('js-landing-shopping-cart');
      if(shoppingCart){
        shoppingCart.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
    }
  }
  
}

function initZoomimage(){
  const images = document.querySelectorAll('.js-zoom-image')
  images.forEach(image => {
    image.addEventListener('click',zoomImageAnimationHandler)
  });
}
