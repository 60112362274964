<template>
  <FormModal :title="title" ref="formModal">
    <template #body>
      <div v-if="layoutBlockData.block_type == 'image'">
        <div class="mka-file-input-wrap">
          <input class="form-control mk-form-control mka-file-input" type="file" ref="fileInput">
        </div>
      </div>

      <div v-else-if="layoutBlockData.block_type == 'video'">
        <input type="text" class="form-control" v-model="layoutBlockData.content">
      </div>

      <div v-else-if="layoutBlockData.block_type == 'textEditor'">
        <div style="border: 1px solid #ccc">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editorRef"
            :defaultConfig="editorConfig.toolbarConfig"
            :mode="editorConfig.mode"
          />
          <Editor
            style="height: 250px; overflow-y: hidden;"
            v-model="layoutBlockData.content"
            :defaultConfig="editorConfig.config"
            :mode="editorConfig.mode"
            @onCreated="editorConfig.handleCreated"
          />
        </div>
      </div>
      <div v-else>
        Not Supported!
      </div>
    </template>

    <template #footer>
      <button type="button" class="btn btn-mka btn--color" @click="submit">
        確定修改
      </button>
    </template>
  </FormModal>
</template>

<script setup>
  import { ref, reactive, defineProps, defineEmits, onBeforeUnmount, watch, shallowRef } from "vue";
  import FormModal from "./form_modal.vue";
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import axios from 'axios';
  import { HandleErrorResponse } from "~/javascripts/utils.js"
  import swal from "sweetalert";

  // data
  const props = defineProps({ layoutBlock: Object });
  const emit = defineEmits(["updateLayoutBlock"])
  const layoutBlockData = reactive({});
  const title = ref("編輯區塊");
  const fileInput = ref();

  watch(() => props.layoutBlock, (newV) => {
    layoutBlockData.order = newV.order;
    layoutBlockData.block_type = newV.block_type;

    switch(newV.block_type) {
      case "image":
        title.value = "編輯圖片"
        layoutBlockData.content = newV.content;
        break;
      case "video":
        title.value = "編輯影片"
        layoutBlockData.content = newV.content;
        break;
      case "textEditor":
        title.value = "編輯文字"
        layoutBlockData.content = newV.content || '<p><br></p>';
        break;
    }
  }, {deep: true})

  // Editor
  const editorRef = shallowRef() // editorRef 必須用 shallowRef(官網說明)
  const editorConfig = ref({
    config: { placeholder: '請輸入內容...' },
    toolbarConfig: {
      toolbarKeys : [
        'bold',
        'underline',
        'italic',
        '|',
        { 
          key: 'group-justify', // required, must start with `group-`
          title: '对齐', // required
          iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>', // optional
          menuKeys: ["justifyLeft", "justifyRight", "justifyCenter"] // required, children menu keys
        },
        'bulletedList',
        'numberedList',
        '|',
        'fontSize',
        '|',
        'redo',
        'undo',
      ],
    },
    mode: 'default',
    handleCreated: (editor) => {
      editorRef.value = editor // record editor instance
    }
  })

  // video
  function youtubeParser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    
    return ( match && match[7].length == 11 ) ? match[7] : false;
  }

  // Modal
  const formModal = ref();
  const _show = () => { formModal.value.show(); }
  const _hide = () => { formModal.value.hide(); }
  async function submit() {
    let currentBlockType = layoutBlockData.block_type;

    if (currentBlockType == "image") {
      // TODO: 完善uploadImage的方式
      if(!fileInput.value.value) return;

      let formData = new FormData();
      formData.append("image", fileInput.value.files[0]);

      axios.post(
        '/api/stores/upload_store_page_image', 
        formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        }
      )
      .then( response => {
        layoutBlockData.content = response?.data?.image?.url
        emit("updateLayoutBlock", layoutBlockData);
      })
      .catch(error => HandleErrorResponse(error))
      .finally(() => { _hide(); })
    } else if (currentBlockType == "video") {
      let youtubeVideoID = youtubeParser(layoutBlockData.content);
      if (!youtubeVideoID) {
        swal({
          title: "提醒",
          text: '請輸入有效的youtube網址',
          dangerMode: true,
        })
        return;
      }

      let newUrl = `https://www.youtube.com/embed/${youtubeVideoID}`;
      layoutBlockData.content = newUrl;

      emit("updateLayoutBlock", layoutBlockData);
      _hide();
    } else {
      emit("updateLayoutBlock", layoutBlockData);
      _hide();
    }
  }

  defineExpose({ 
    show: _show,
    hide: _hide
  });

  onBeforeUnmount(() => {
    const editor = editorRef.value
    if (editor == null) return
    editor.destroy()
  })
</script>

<style scoped lang="scss">
  :deep p{
    font-size: 1rem;
  }
  :deep input{
    height: auto;
  }
  :deep .w-e-text-container p{
    margin: 0.5rem 0;
  }
  .btn--color{
    background-color: #ee7c26;
    color: #fff;
    &:hover{
      background-color: #d67022;
    }
  }
</style>