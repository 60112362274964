<template>
  <div :class="[showModal ? 'fadeOutNotification': '', 'notification-modal']">
    <img v-if="status==='succession'" :src="GetImageUrl('icons/white_check.svg')" alt="x" class="notification-succession">
    <img v-if="status==='error'" :src="GetImageUrl('icons/red_x.svg')" alt="x" class="notification-error">
    <span>{{msg}}</span>
  </div>
</template>

<script setup>
import { GetImageUrl } from "~/javascripts/utils.js";
import { ref, defineProps, defineEmits, computed, reactive,watch,onMounted } from 'vue'

  const props = defineProps({
    statusModalSetting: { 
      type: Object,
      default: () => {}
    },
    statusModalShow: {
      type: Boolean,
      default: () => false
    }
  });

  const showModal = ref(false);
  const status = ref('');
  const msg = ref('');

  watch(()=>props.statusModalShow, (newValue)=>{
    showModal.value = true;
    setTimeout(() => showModal.value = false, 2500);
    msg.value = props.statusModalSetting.msg;
    status.value = props.statusModalSetting.status;
  })

</script>