const searchResetSelector     = '.js-resource-search-reset';
const searchResetBtnSelector  = '.js-resource-search-reset-btn';
const searchInputSelector     = '.js-resource-search-input'

const displayNoneClass = "d-none";

class SearchReset {
  constructor(el) {
    this.el = el;
    this.init();
  }

  resetHandler() {
    this.input.value = '';
    this.input.dispatchEvent(new Event("input"));
  }

  showResetBtnHandler() {
    if(this.input.value !== "") {
      this.resetBtn.classList.remove(displayNoneClass);
    } else {
      this.resetBtn.classList.add(displayNoneClass);
    }
  }

  init() {
    const resetBtn = document.querySelector(searchResetBtnSelector);
    if(resetBtn) {
      this.resetBtn = resetBtn;
      this.resetBtn.addEventListener('click', this.resetHandler.bind(this));
    }
    const input = document.querySelector(searchInputSelector);
    if(input) {
      this.input = input;
      this.input.addEventListener("input", this.showResetBtnHandler.bind(this));
    }
    this.input.dispatchEvent(new Event("input"));
  }
}

export default function NewSearchReset() {
  document
  .querySelectorAll(searchResetSelector)
  .forEach((el) =>{
    new SearchReset(el);
  }); 
}
