<template>
  <div class="modal-content wallet-modal__content">
    <template v-if="showContent == 'withdraw'">
      <div class="modal-header wallet-modal__header wallet-modal__header--border" >
        撥款明細
        <i class="bi bi-x close-btn" role="button" title="關閉" data-bs-dismiss="modal"></i>
      </div>
      <div class="modal-body detail-container">
        <div class="wallet-modal__section wallet-modal__section--height">
          <div class="detail-container__title detail-container__title--large">-NT${{ formatCurrency(withdrawDetailInfo.amount) }}</div>
          <div v-if="withdrawDetailInfo.finished_at" class="detail-container__success-status detail-container__success-status--large">撥款成功</div>
        </div>
        <div class="wallet-modal__section wallet-modal__section--last">
          <div class="mb-4">
            <div class="detail-container__title mb-2">提款至</div>
            <div>{{ withdrawDetailInfo.bank_name }} * {{ withdrawDetailInfo.transferee_card }}</div>
          </div>
          <ul class="wallet-modal__list-row wallet-modal__list-row--top-border">
            <li><span>提款手續費</span><span>NT${{ formatCurrency(withdrawDetailInfo.handling_fee) }}</span></li>
            <li><span>入帳金額</span><span>NT${{ formatCurrency(withdrawDetailInfo.final_amount) }}</span></li>
            <li><span>提款編號</span><span>{{ withdrawDetailInfo.trade_no }}</span></li>
            <li><span>建立時間</span><span>{{ withdrawDetailInfo.created_at }}</span></li>
            <li v-if="withdrawDetailInfo.finished_at"><span>完成時間</span><span>{{ withdrawDetailInfo.finished_at }}</span></li>
          </ul>
          <div class="detail-container__detail-bar">
            <ul v-if="withdrawDetailInfo.finished_at" class="detail-list">
              <li class="detail-list__item detail-list__item--first detail-container__success-status">撥款成功</li>
              <li class="detail-list__item">NT${{ formatCurrency(withdrawDetailInfo.amount) }}已經成功匯入 {{ withdrawDetailInfo.bank_name }}</li>
              <li class="detail-list__item">{{ withdrawDetailInfo.finished_at }}</li>
            </ul>
            <ul class="detail-list">
              <li class="detail-list__item detail-list__item--first">申請成功，準備撥款</li>
              <li class="detail-list__item">撥款已經通知{{ withdrawDetailInfo.bank_name }} 進行處理</li>
              <li class="detail-list__item">{{ withdrawDetailInfo.created_at }}</li>
            </ul>
          </div>
        </div>
        <div class="wallet-modal__tips">
          如對撥款明細有任何問題，請與客服聯繫。
        </div>
      </div>
      
    </template>
    <template v-else-if="showContent == 'income'">
      <div class="modal-header wallet-modal__header wallet-modal__header--border" >
        收益明細
        <i class="bi bi-x close-btn" role="button" title="關閉" data-bs-dismiss="modal"></i>
      </div>
      <div class="modal-body detail-container">
        <div class="wallet-modal__section">
          <ul class="wallet-modal__list">
            <li class="detail-container__title detail-container__title--list"><span>入帳金額</span><span>NT${{ formatCurrency(incomeDetailInfo.amount) }}</span></li>
            <li><span>訂單編號</span><span>{{ incomeDetailInfo.order_number }}</span></li>
            <li><span>成立時間</span><span>{{ incomeDetailInfo.created_at }}</span></li>
            <li><span>完成時間</span><span>{{ incomeDetailInfo.finished_at }}</span></li>
            <li><span>入帳時間</span><span>{{ incomeDetailInfo.received_at }}</span></li>
          </ul>
        </div>
        <div class="wallet-modal__section">
          <ul class="wallet-modal__list">
            <li class="detail-container__title detail-container__title--list"><span>銷售收入</span><span>NT${{ formatCurrency(incomeDetailInfo.sales_revenue) }}</span></li>
            <li><span>產品收入</span><span>NT${{ formatCurrency(incomeDetailInfo.revenue) }}</span></li>
            <li><span>金流收入</span><span>NT${{ formatCurrency(incomeDetailInfo.payment_price) }}</span></li>
            <li><span>運費收入</span><span>NT${{ formatCurrency(incomeDetailInfo.shipping_price) }}</span></li>
            <li><span>{{ incomeDetailInfo.user_level }}分潤</span><span>NT${{ formatCurrency(incomeDetailInfo.member_profit) }}</span></li>
          </ul>
        </div>
        <div class="wallet-modal__section wallet-modal__section--last">
          <ul class="wallet-modal__list">
            <li class="detail-container__title detail-container__title--list"> <span>成本</span><span>-NT${{ formatCurrency(incomeDetailInfo.sales_cost) }}</span></li>
            <li><span>產品</span><span>-NT${{ formatCurrency(incomeDetailInfo.items_cost) }}</span></li>
            <li><span>金流</span><span>-NT${{ formatCurrency(incomeDetailInfo.payment_price) }}</span></li>
            <li><span>物流</span><span>-NT${{ formatCurrency(incomeDetailInfo.shipping_price) }}</span></li>
            <li><span>代發</span><span>-NT${{ formatCurrency(incomeDetailInfo.proxy_sale_cost) }}</span></li>
            <li><span>優惠券</span><span>-NT${{ formatCurrency(incomeDetailInfo.coupon_amount) }}</span></li>
          </ul>
        </div>
        <div class="wallet-modal__tips">
          依個資法平台不提供消費者資料，如有任何訂單金額問題，請聯繫客服處理。
        </div>
      </div>
    </template>
    <div class="modal-footer wallet-modal__footer wallet-modal__footer--no-border">
      <div class="mk-btn go-back-btn" data-bs-dismiss="modal">返回</div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits, computed, reactive, watch, onMounted } from 'vue'
  import axios from 'axios';
  import { HandleErrorResponse } from "~/javascripts/utils.js";
  
  let walletTransactionID = null;
  const withdrawDetailInfo = reactive({
    amount:           0,
    bank_code:        '',
    bank_name:        '',
    transferee_card:  '',
    created_at:       '',
    final_amount:     '',
    finised_at:       null,
    handling_fee:     0,
    trade_no:         '',
  })

  const incomeDetailInfo = reactive({
    amount:          0,    // 入帳金額
    sales_cost:      0,    // 成本
    coupon_amount:   0,    // 優惠券成本
    created_at:      null, // 成立時間
    finished_at:     null, // 完成時間
    items_cost:      0,    // 產品成本
    revenue:         0,    // 產品收入
    order_number:    null, // 訂單編號
    payment_price:   0,    // 金流成本
    proxy_sale_cost: 0,    // 代發成本
    received_at:     null, // 入帳時間
    sales_revenue:   0,    // 銷售收入
    shipping_price:  0,    // 運費成本
    member_profit:   0,    // 會員分潤
    user_level:      '',   // 會員等級
  })

  const showContent = ref('')

  onMounted(() => {
    const detailModal = document.querySelector(".js-detail-modal")
    if(detailModal) {
      detailModal.addEventListener('show.bs.modal' , (event) => {
        const relatedDiv = event.relatedTarget;
        walletTransactionID = relatedDiv.getAttribute('data-wt-id');
        getDetailInfo();
      })
      detailModal.addEventListener('hide.bs.modal', () => {
        cleanDetailInfo();
      });
    }
  });

  const getDetailInfo = () => {
    if(!walletTransactionID) return
    axios.get(`/api/wallets/detail/${walletTransactionID}`).then((resp) => {
      const data = resp.data.data;
      if(data.action === 'withdraw') {
        showContent.value = 'withdraw'
        withdrawDetailInfo.amount           = StringToNumber(data.amount);
        withdrawDetailInfo.bank_code        = data.bank_code;
        withdrawDetailInfo.bank_name        = data.bank_name;
        withdrawDetailInfo.transferee_card  = data.transferee_card;
        withdrawDetailInfo.created_at       = data.created_at;
        withdrawDetailInfo.final_amount     = StringToNumber(data.final_amount);
        withdrawDetailInfo.finished_at      = data.finished_at;
        withdrawDetailInfo.handling_fee     = StringToNumber(data.handling_fee);
        withdrawDetailInfo.trade_no         = data.trade_no;
      } else if (data.action === "income") {
        showContent.value = 'income'
        incomeDetailInfo.amount          = StringToNumber(data.amount);
        incomeDetailInfo.sales_cost      = StringToNumber(data.sales_cost);
        incomeDetailInfo.coupon_amount   = StringToNumber(data.coupon_amount);
        incomeDetailInfo.created_at      = data.created_at;
        incomeDetailInfo.finished_at     = data.finished_at;
        incomeDetailInfo.items_cost      = StringToNumber(data.items_cost);
        incomeDetailInfo.revenue         = StringToNumber(data.revenue);
        incomeDetailInfo.order_number    = data.order_number
        incomeDetailInfo.payment_price   = StringToNumber(data.payment_price);
        incomeDetailInfo.proxy_sale_cost = StringToNumber(data.proxy_sale_cost);
        incomeDetailInfo.received_at     = data.received_at
        incomeDetailInfo.sales_revenue   = StringToNumber(data.sales_revenue);
        incomeDetailInfo.shipping_price  = StringToNumber(data.shipping_price);
        incomeDetailInfo.member_profit   = StringToNumber(data.member_profit);
        incomeDetailInfo.user_level      = data.user_level;
      }
      
    }).catch((err) => {
      HandleErrorResponse(err)
    })
  } 


  const StringToNumber = (value) => { // 四捨五入
    const stringValue = value.toString();
    const validNumber = /^-?\d*\.?\d+$/.test(stringValue.trim());
    return validNumber ? Math.round(parseFloat(stringValue)) : 0;
  }

  const formatCurrency = (value,format) => {
    // 检查value是否是数字
    if (isNaN(value)) {
      return value; // 如果不是数字，直接返回原始值
    }
    // 使用toLocaleString()方法将数字格式轉化,超過位數四捨五入
    return value.toLocaleString('zh-TW', { style: format, currency: 'TWD',minimumFractionDigits: 0, maximumFractionDigits: 0});
  };

  const cleanDetailInfo = () => {
    this.showContent.value = '';
  }
</script>

<style lang="scss" scoped>
  $mk-orange:#ee7c26;
  .detail-container {
    padding: 1rem 0;
    &__success-status {
      font-size: 1rem;
      color: $mk-orange;
      &--large {
        font-size: 1.25rem;
      }
    }
    &__title {
      font-weight: bolder;
      color: #000000;
      &--large {
        font-size: 2rem;
      }
      &--list {
        margin-bottom: 0.15rem;
        font-size: 1.375rem;
        color: #333;
      }
    }
    &__detail-bar {
      position: relative;
      margin: 0.75rem 1rem 0;
      border-left: 0.5px solid #B3B3B3;
      &::before{
        content: '';
        position: absolute;
        top: 0px;
        left: -5px;
        display: block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: $mk-orange;
      }
      .detail-list {
        margin-bottom: 1.5rem;
        padding-left: 1.25rem;
        &__item {
          padding: 0.375rem 0;
          &--first {
            padding-top: 0;
          }
        }
      }
      .detail-list:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0.25rem;
      }
    }
  }
</style>