export default function InitUserCouponForm() {
  const formID = "user-coupon-form" 
  const form   = document.getElementById(formID)
  if (!form ) return false;
  document.addEventListener("DOMContentLoaded", () => {
    document.querySelector('.js-reload-btn').addEventListener('click', ()=>{
      location.reload()
    })
    // const toggle =  document.querySelector('.js-coupon-toggle');
    // toggle.addEventListener("change", () => {
    //   const validationInputs =  document.querySelectorAll('.js-validation-switch')
    //   if(toggle.checked) {
        
    //     validationInputs.forEach(el => {
    //       el.classList.add('js-validtion-input')
    //       console.log(el.parentNode)
    //     });
    //   } else {
    //     validationInputs.forEach(el => {
    //       el.classList.remove('js-validtion-input');
        
    //     })
    //   }
    // })
  });
}