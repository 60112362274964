class InputFeedback {
  constructor(inputEl) {
    this.inputEl = inputEl
    this.setFeedback();
  }

  setFeedback() {
    this.feedbackClass       = "mk-form-feedback";
    this.invalidControlClass = "mk-form-control--invalid";

    this.feedbackEl = document.createElement("div");
    this.feedbackEl.classList.add(this.feedbackClass);
    this.inputEl.parentElement.insertBefore(this.feedbackEl, this.inputEl.nextSibling);
  }

  clear() {
    this.feedbackEl.classList.toggle(`${this.feedbackClass}--sucess`, false);
    this.feedbackEl.classList.toggle(`${this.feedbackClass}--invalid`, false);

    this.inputEl.classList.toggle(this.invalidControlClass, false);
    this.feedbackEl.innerHTML = "";
  }

  setValidMsg(msg){
    this.setMsg(true, msg);
  }

  setInvalidMsg(msg){
    this.setMsg(false, msg);
  }

  setMsg(valid, msg) {
    this.clear();
    let type = null;
    let hide = false;

    if (valid) {
      type = "sucess";
    } else {
      type = "invalid";
    }

    this.inputEl.classList.toggle(this.invalidControlClass, type === "invalid");

    if (hide) {
      this.feedbackEl.classList.toggle(`${this.feedbackClass}--hide`, true);
    } else {
      this.feedbackEl.classList.toggle(`${this.feedbackClass}--hide`, false);
      this.feedbackEl.classList.toggle(`${this.feedbackClass}--${type}`, true);
      this.feedbackEl.innerHTML = msg;
    }
  }
}

export default function NewInputfeedback(el) {
  if (!el) {
    return;
  }

  return new InputFeedback(el);
}