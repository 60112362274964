import { createApp } from 'vue'
import StorePage from './store_page/store_page.vue'
import { createPinia } from 'pinia'

export default function InitUserStoreStorePagePage() {
  const storePageSelector = "#js-store-page";
  if (!document.querySelector(storePageSelector)) return false;
  // console.log(document.querySelector(storePageSelector).dataset)
  const {storePageId} = document.querySelector(storePageSelector).dataset;

  const pinia = createPinia();
  const storePageApp = createApp(StorePage,{storePageId: storePageId});

  storePageApp.use(pinia);
  storePageApp.mount(storePageSelector);
};