<!-- TODO 驗證、驗證訊息CSS -->
<template>
  <div>
    <div class="fs-5 mb-3">收件人資料</div>
    <div class="recipient-form row">
      <div class="col-lg-6 col-md-12">
        <!-- 收件人 -->
        <div class="recipient-form__block recipient-form__block--required input-group">
          <label class="recipient-form__label input-group-text">收件人</label>
          <Field 
            v-model="personalData.name" 
            placeholder="請填寫真實姓名" 
            class="recipient-form__input form-control" 
            name="name"
          />
          <ErrorMessage as="div" name="name" class="recipient-form__error">
            請輸入中文2~5個字或是英文4~10個字
          </ErrorMessage>
        </div>
        
        <!-- 連絡電話 -->
        <div class="recipient-form__block recipient-form__block--required input-group">
          <label class="recipient-form__label input-group-text">連絡電話</label>
          <Field 
            v-model="personalData.cellphone" 
            placeholder="請填寫快遞可連絡到您的號碼" 
            class="recipient-form__input form-control" 
            name="cellphone" 
          />
          <ErrorMessage as="div" name="cellphone" class="recipient-form__error">
            請填入正確的手機號碼
          </ErrorMessage>
        </div>
        <!-- 縣市區域 -->
        <div class="row" v-if="!showCVS && showCounty">
          <!-- 縣市 -->
          <div class="col-6">
            <div class="recipient-form__block recipient-form__block--select recipient-form__block--required input-group">
              <label class="recipient-form__label recipient-form__label--select input-group-text">縣市
                <ErrorMessage as="div" name="city" class="recipient-form__error d-none d-lg-block">
                  請選擇縣市
                </ErrorMessage>
              </label>
              <Field 
                v-model="personalData.cityID" 
                class="recipient-form__input recipient-form__input--select form-select" 
                as="select"
                name="city" 
              >
                <option disabled value="">請選擇</option>
                <option v-for="citie in cities" :key="citie.id" :value="citie.id">{{ citie.name }}</option>
              </Field>
              <ErrorMessage as="div" name="city" class="recipient-form__error d-block d-lg-none">
                請選擇縣市
              </ErrorMessage>
            </div>
          </div>
          <!-- 區域 -->
          <div class="col-6">
            <div class="recipient-form__block recipient-form__block--required input-group">
              <label class="recipient-form__label recipient-form__label--select input-group-text">區域
                <ErrorMessage as="div" name="area" class="recipient-form__error d-none d-lg-block">
                  請選擇區域
                </ErrorMessage>
              </label>
              <Field 
                v-model="personalData.districtID" 
                placeholder="請填寫快遞可連絡道您的號碼" 
                class="recipient-form__input recipient-form__input--select form-select" 
                as="select"
                name="area" 
              >
                <option disabled value="">請先選擇縣市</option>
                <option v-for="district in districtOptions" :key="district.id" :value="district.id">{{ district.name }}</option>
              </Field>
              <ErrorMessage as="div" name="area" class="recipient-form__error d-block d-lg-none">
                  請選擇區域
              </ErrorMessage>
            </div>
          </div>
        </div>
        <!-- 重新整理資料回來創造元素資料可能有問題 -->
        <!-- 街道地址 -->
        <div v-if="!showCVS">
          <div class="recipient-form__block recipient-form__block--required input-group m-lg-0">
            <label class="recipient-form__label input-group-text">街道地址</label>
            <Field 
              v-model="personalData.address" 
              :placeholder= "showCounty ? '不用重複輸入縣市' : '請輸入完整地址' "
              class="recipient-form__input form-control" 
              name="address"
            />
            <ErrorMessage as="div" name="address" class="recipient-form__error">
              請輸入街道地址
            </ErrorMessage>
          </div>
        </div>
        <!-- 超商 -->
        <div class="mb-3" v-if="showCVS">
          <div class="recipient-form__block recipient-form__block--cvs recipient-form__block--required input-group">
            <label class="recipient-form__label input-group-text">取貨超商</label>
            <Field v-model="cvsStore" 
              placeholder="尚未選取超商" 
              name="superMarket" 
              class="recipient-form__input form-control" readonly 
            />
            <button type="button" @click="handleSelectCvs" class="btn btn-light cvs-btn">
              <i class="bi bi-shop me-1"></i>點擊選取超商
            </button>
            <ErrorMessage as="div" name="superMarket" class="recipient-form__error">
              請點擊選取超商
            </ErrorMessage>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <!-- Email -->
        <div class="recipient-form__block recipient-form__block--required input-group">
          <label class="recipient-form__label input-group-text">Email</label>
          <Field v-model="personalData.email" 
            type="email" 
            name="email" 
            class="recipient-form__input form-control" />
          <ErrorMessage as="div" name="email" class="recipient-form__error">
            請按照Email格式填寫
          </ErrorMessage>
        </div>
        <!-- 公司抬頭 -->
        <div class="recipient-form__block recipient-form__block input-group">
          <label class="recipient-form__label input-group-text" id="coTitle">公司抬頭</label>
          <Field 
            v-model="personalData.companyTitle" 
            placeholder="發票訊息" 
            type="text" 
            name="companyTitle" 
            class="recipient-form__input form-control" 
          />
        </div>
        <!-- 公司統編 -->
        <div class="recipient-form__block recipient-form__block input-group">
          <label class="recipient-form__label input-group-text" id="taxNumber">公司統編</label>
          <Field 
            v-model="personalData.taxIDnumber" 
            placeholder="發票訊息" 
            type="text" 
            name="taxIDnumber" 
            class="recipient-form__input form-control" 
          />
        </div>
        <div class="recipient-form__block recipient-form__block recipient-form__block--textarea input-group m-lg-0">
          <label class="recipient-form__label recipient-form__label--textarea input-group-text">備註</label>
          <textarea 
            v-model="personalData.customerNote" placeholder="有什麼特殊需求可以留言給我們" 
            type="text" 
            class="recipient-form__input recipient-form__input--textarea js-textarea-adapt form-control">
          </textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, watch, defineProps, defineEmits, onMounted } from "vue"

  // 驗證測試yup系統化
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import TextareaApapt from '~/javascripts/app/users_form/textarea_adapt.js';
  
  onMounted (() => {
    TextareaApapt();
  })

  const emit = defineEmits([
    "storeAndChangeStepTo",
  ]);

  const props = defineProps({
    personalData: {
      type: Object,
      default: () => {}
    },
    cities: {
      type: Array,
      default: () => []
    },
  });

  // 點擊選取超商 全家 7-11 // TODO
  const cvsMapParams = ref();
  const cvsMapUrl = ref();
  const ecpayParams = ref();
  const ecpayUrl = ref();

  const districtOptions = computed(() => {
    // 依"縣市"的選擇提供"區域"的選項
    let selectedCityID = props.personalData.cityID;
    let currentCity = props.cities.find(city => city.id == selectedCityID);

    return currentCity?.districts || [];
  })

  const cvsStore = computed(() => {
    const defaultName = ""
    const storeName = props.personalData.CVSStoreName;
    let cvsType = "";

    switch (props.personalData?.shippingType) {
      case "UNIMARTC2C":
        cvsType = "7-ELEVEN"
        break;
      case "FAMIC2C":
        cvsType = "全家"
        break;
    }

    return storeName && cvsType ? `${cvsType} ${storeName}` : defaultName
  })

  const showCVS = computed(() => ["FAMIC2C", "UNIMARTC2C"].includes(props.personalData.shippingType))
  const showCounty = computed(() => ["HOME"].includes(props.personalData.shippingType))

  // 點擊選取超商抓地圖資料 , 帶parems，金流+物流同個funtion不同
  function handleSelectCvs() {
    emit('storeAndChangeStepTo', "2", "goMap")
  };
</script>

<style lang="scss" scoped>
  .recipient-form{
    &__block{
      margin-bottom: 1.5rem;
      border: 1px solid rgba(217, 217, 217, 1);
      height: 42px;
      &--required::after{
        position: absolute;
        right: 12px;
        top: 2px;
        color: #f94816;
        content: "*";
        font-family: arial;
        font-size: 30px; 
      }
      &--textarea{
        min-height: 42px;
        height: auto;
      }
    }
    &__label{
      flex-basis: 94px;
      border-radius: 0;
      border: none;
      padding-right: 0;
      background-color: #fff;
      font-size: 1.15rem;
      &--select{
        flex-basis: 100%;
        padding-bottom: 0;
        height: 30px;
        font-size: 1.1rem;
      }
    }
    &__input{
      // height: 40px;
      border-radius: 0;
      // border-left: 1px solid #fff;
      outline: 0;
      box-shadow: none;
      border: none;
      text-align: left;
      &--select{
        padding-top: 0;
        border: 1px solid rgba(217, 217, 217, 1);
        border-top: none;   
        line-height: 1rem;
        font-size: 0.9rem;
      }
      &--textarea{
        line-height: 1.75;
        height: 2rem;
        resize: none;
        overflow-y: hidden;
        &::placeholder{
          line-height: 2;
        }
      }
      &::placeholder{
        text-align: left;
        font-size: 14px !important;
      }
    }
    &__error{
      display: block;
      margin: 0.25rem 0;
      width: 100%;
      color: #ED1C24;
      font-size: 13px;
    }
    .cvs-btn{
      width: 100%;
      border-radius: 0;
      border-top: 1px solid rgba(217, 217, 217, 1);
    }
  }

  @media screen and (max-width:991px ){
    .recipient-form{
      &__block{
        margin-bottom: 1.25rem;
        &--required::after{
          font-size: 22px; 
        }
        &--cvs{
          margin-bottom: 3.75rem;
        }
        &--select{
          height: 54px;
        }
        &--textarea{
          min-height: 100px;
          height: auto;
        }
      }
      &__label{
        flex-basis: auto;
        font-size: 1rem;
        &--select{
          flex-basis: 100%;
        }
        &--textarea{
          height: 42px;
        }
      }
      &__input{
        &--textarea{
          padding-top: 0.5rem;
          height: auto;
        }
      }
      &__error{
        font-size: 12px;
      }
    }
  }
</style>
