import { createApp } from 'vue'
import CheckoutApp from './components/Checkout.vue'
import { createPinia } from 'pinia'

export default function InitCheckout() {
  const checkoutAppSelector = "#js-checkout";
  const el = document.querySelector(checkoutAppSelector);
  if (!el) return false;

  const checkoutApp = createApp(CheckoutApp);
  const pinia = createPinia();
  checkoutApp.use(pinia);
  checkoutApp.mount(el);


};