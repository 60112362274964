<template>
  <div class="page-container">
    <div class="page-container__title">
      <h2 class="text-dark">商店設定</h2>
    </div>
    <div class="main-content">
      <div class="js-validation-form store-page">
        <NotifySection
          ref="notifySection"
          v-model:toggle="storePage.notify"
          v-model:content="storePage.notify_content"
          v-model:validation="notifyValidation">
        </NotifySection>
        <hr/>
        <LayoutBlockSection
          ref="layoutBlockSection"
          :storePageID="props.storePageId"
          :landingSalesUrl="storePage.landing_sales_url"
          v-model:storePageLayoutBlocks="storePage.store_page_layout_blocks"
          v-model:validation="layoutBlockValidation">
        </LayoutBlockSection>
      
        <div class="form-btns">
          <div class="btn users-hollow-btn" @click="reloadPage">捨<span class="only-mobile empty">&emsp;</span>棄</div>
          <div class="btn users-solid-btn" @click="handleSubmit" :disabled="store.saving" >保<span class="only-mobile empty">&emsp;</span>存</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits, computed, reactive, watch, onMounted } from "vue"
  import LayoutBlockSection from "./store_page_layout_blocks.vue"
  import NotifySection  from "./store_page_notify.vue"
  import {useResourceFormStore} from './store_page_form_store.js'
  import axios from 'axios';
  
  const store = useResourceFormStore();

  onMounted(async () => {
    store.api       = '/api/stores/';
    const data = await store.getResource();
  
    storePage.notify = data.store_page.notify;
    storePage.notify_content = data.store_page.notify_content;
    storePage.store_page_layout_blocks = data.store_page.store_page_layout_blocks;
    storePage.landing_sales_url = data.store_page.landing_sales_url 
    console.log(storePage)

  });
  
  const props = defineProps(["storePageId"]);

  const notifySection = ref();
  const notifyValidation = ref(true);
  const layoutBlockSection = ref();
  const layoutBlockValidation = ref(true);

  // const storePageID = props.storePageId;

  const storePage = reactive({
    // id:                      props.storePageId,
    notify:                  false,
    notify_content:          '',
    store_page_layout_blocks: [], // 產品介紹區塊
  
  });

  async function handleSubmit(){
    if(submitValidation()) {
      console.log('sucess submit')
      const params = {
        notify:                             storePage.notify,
        notify_content:                     storePage.notify_content,
        store_page_layout_blocks_attributes:storePage.store_page_layout_blocks
      }
      
      const resp = await store.saveResource(params);
      if(resp) {
        storePage.notify = resp.data.store_page.notify;
        storePage.notify_content = resp.data.store_page.notify_content;
        storePage.store_page_layout_blocks = resp.data.store_page.store_page_layout_blocks;
      }
    } else {
      console.log('validation error');
    }
    // console.log('-------------------------------');
    // console.log(storePage.notify)
    // console.log(storePage.notify_content)
    // console.log(notifyValidation.value); 
    // console.log(storePage.store_page_layout_blocks);
    // console.log(layoutBlockValidation.value)
    // console.log('-------------------------------');
  }

  const submitValidation = () => {
    if(storePage.notify) notifySection.value.handleInputValidation(true);
    layoutBlockSection.value.layoutBlocksExist();
    return notifyValidation.value && layoutBlockValidation.value
  }

  const reloadPage = () => {
    window.location.reload();
  }

 
 
</script>

<style scoped lang="scss">
$mk-orange:#ee7c26;

:deep .store-page{
  &__notify{
    p{
      display: inline-block;
    }
  }
}

@media screen and (max-width: 768px){
  .only-mobile.empty{
    display: inline-block ; 
  }
}
</style>