import NewFlatPickr from '~/javascripts/flatpickr.js';
import swal from "sweetalert";

export default function InitUserStoreIndexPage() {
  const pageID = "user-store-performace" 
  const page   = document.getElementById(pageID)
  if (!page) return false;
  document.addEventListener("DOMContentLoaded", () => {
    initViewMode();
    NewFlatPickr({ignoreSec: true, disableMobile: true});
    formValidate();
  });
}

function initViewMode() {
  const defaultMode = document.querySelector('.js-performace-default').value;
  modeShowControl(defaultMode);

  const dropdownBtns = document.querySelectorAll('.js-dropdown-select-interval');
  
  dropdownBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      document.querySelectorAll('.js-interval-control').forEach(el => {
        el.removeAttribute('data-no-repeat');
      });
    })
  });

  const viewModeBtns = document.querySelectorAll('.js-interval-control')

  viewModeBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      const mode = btn.getAttribute('data-value')
      btn.setAttribute('data-no-repeat', true);
      document.querySelectorAll(`.js-interval-control[data-value=${mode}]`).forEach((el) => {
        if(btn !== el && !el.getAttribute('data-no-repeat')) el.click();
      })
      modeShowControl(mode);
    })
  })
}

function modeShowControl(mode) {
  const dayInterval = document.querySelector('.js-day-interval');
  const monthInterval = document.querySelector('.js-month-interval');
  const dayIntervalModal = document.querySelector('.js-day-interval-modal');
  const monthIntervalModal = document.querySelector('.js-month-interval-modal');
  if(mode === 'daily') {
    monthInterval.classList.add('d-none')
    monthIntervalModal.classList.add('d-none')
    dayInterval.classList.remove('d-none')
    dayIntervalModal.classList.remove('d-none')

  } else if(mode === 'monthly') {
    dayInterval.classList.add('d-none')
    dayIntervalModal.classList.add('d-none')
    monthInterval.classList.remove('d-none')
    monthIntervalModal.classList.remove('d-none')
  } 
}

function formValidate() {
  const formSelector = '.js-store-performance-search';
  const formArray = document.querySelectorAll(formSelector);
  formArray.forEach(form => {
    form.addEventListener('submit', function(event) {
      const startDate = form.querySelector('input[name="sd"]').value;
      const endDate = form.querySelector('input[name="ed"]').value;
      console.log(startDate,endDate)
  
      if (!startDate || !endDate) {
        event.preventDefault();
        swal({
          title: "提醒",
          text: '請填寫所有日期欄位',
          dangerMode: true,
        })
      }
    });
  });
}
