// pages js
import UsersDesignsIndexPage from './users/designs/index.js';
import UsersDesignsShowPage from './users/designs/show.js';

const pages = [
  {
    name: "users-designs-index",
    selector: "#users-designs-index, #users-designs-index-desktop",
    pageJS: UsersDesignsIndexPage
  },
  {
    name: "users-designs-show",
    selector: "#users-designs-show",
    pageJS: UsersDesignsShowPage
  }
];

pages.forEach( pageInfo => {
  let pageElement = document.querySelectorAll(pageInfo.selector);
  if (pageElement.length > 0 && pageInfo.pageJS) new pageInfo.pageJS;
})
