<script setup>
//-
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue"
import axios from 'axios';
//-
import PayModal from "./PayModal.vue";
import NoticeModal from "./NoticeModal.vue"
import CounterGroup from "~/vues/components/setups/CounterGroup.vue";
import { HandleErrorResponse } from "~/javascripts/utils.js";
import swal from "sweetalert";


// 定義 ref 變數
const paymentOptions = ref([]); // 付費方式選項
const shippingOptions = ref([]); // 運送方式選項
const designs = ref([]);
const cost_details = ref({});
const checkOutForm = ref({});
const cities = ref([]);
const selectDesignIds = ref();
const disabledUpdate = ref(false);
const noticeMessage = ref('');
const noticeNextUrl = ref('');
const invalidDesignIds = ref([]);

function getCartInfo() {
  axios.post("/api/carts/cart_info")
    .then(response => {
      const cartInfo = response.data.data;

      // 更新 ref 變數的值
      paymentOptions.value = cartInfo?.payment_options;
      shippingOptions.value = cartInfo?.shipping_options;
      designs.value = cartInfo.designs;
      cost_details.value = cartInfo.cost_details;
      checkOutForm.value = cartInfo.check_out_form;
      cities.value = cartInfo.cities;
      invalidDesignIds.value = cartInfo.invalid_designs;
      selectDesignIds.value = cartInfo.select_designs.filter(item => !invalidDesignIds.value.includes(item));
      updateSelectedDesigns(cartInfo);
    })
    // TODO 同時step2 跟 有選selectID
    .then(() => {
      if(checkOutForm.value !== null){
        if (checkOutForm.value.step == 2 && selectDesignIds.value.length > 0) {
          openModal(checkOutForm.value.step);
        } else if (checkOutForm.value.step == 3) {
          openModal(checkOutForm.value.step);
        }
      }
    
    })
    .catch((err) => {
      console.error("資料抓取錯誤:", err);
      HandleErrorResponse(err, () => reloadCartInfo());
    })
}

function updateSelectedDesigns(cartInfo) {
  designs.value.forEach(design => {
    design.selected = cartInfo.select_designs.filter(item => !invalidDesignIds.value.includes(item)).includes(design.design_id)
  })
}

async function deleteDesign(index, designId) {
  let deleteDesignData = {
    data: {
      select_design_ids: selectDesignIds.value, // 將 index 添加到選中的設計中
      update_info: {
        type: "delete_design",
        design_id: designId  // 使用傳入的 designId
      }
    },
  };
  try {
    const response = await axios.post('/api/carts/update_cart', deleteDesignData)
    const cartInfo = response.data.data;
    designs.value.splice(index, 1);
    // checkOutForm list
    designs.value = cartInfo?.designs
    cost_details.value = cartInfo?.cost_details
    // TODO
    updateSelectedDesigns(cartInfo);
  } catch(err) {
    HandleErrorResponse(err);
  }
  
};

async function selectDesign(designID) {
  //TODO
  selectDesignIds.value = designs.value.filter(e => e.selected === true).map(e => e.design_id)

  const selectDesignData = {
    data: {
      select_design_ids: selectDesignIds.value // 初始化為空陣列
    }
  };
  try {
    const response = await axios.post('/api/carts/update_cart', selectDesignData)
    const cartInfo = response.data.data;
    designs.value = cartInfo?.designs
    cost_details.value = cartInfo?.cost_details
    // TODO
    updateSelectedDesigns(cartInfo);
  } catch (err) {
    HandleErrorResponse(err, () => reloadCartInfo());
  }
}

async function deleteItem(item) {
  const deleteItemData = {
    data: {
      select_design_ids: selectDesignIds.value,
      update_info: {
        type: 'delete_item',
        line_item_id: item.line_item_id
      }
    }
  };

  try {
    const response = await axios.post('/api/carts/update_cart', deleteItemData);
    const cartInfo = response.data.data;
    designs.value = cartInfo?.designs;
    cost_details.value = cartInfo?.cost_details;
    // TODO
    updateSelectedDesigns(cartInfo);

    return; // 已找到並刪除，退出函數
  }
  catch(err) {
    HandleErrorResponse(err, () => reloadCartInfo());
  }
};

// 加減＆輸入數字功能
async function handleUpdatedItem(designID, item) {
  selectDesignIds.value = designs.value.filter(e => e.selected === true).map(e => e.design_id)
  const countUpdatedData = {
    data: {
      select_design_ids: selectDesignIds.value,
      update_info: {
        design_id: designID,
        type: 'update',
        line_item: {
          size_id: item.size_id,
          size: item.size,
          quantity: item.quantity,
        }
      }
    }
  };

  disabledUpdate.value = true;
  try {
    const response = await axios.post('/api/carts/update_cart', countUpdatedData);
    const cartInfo = response.data.data;
    designs.value = cartInfo?.designs;
    cost_details.value = cartInfo?.cost_details;
    updateSelectedDesigns(cartInfo);
    disabledUpdate.value = false;
  } catch(err) {
    HandleErrorResponse(err, () => reloadCartInfo());
  }
}

// 新增尺寸
async function addSize(designID, variantItem) {
  const addSiezdData = {
    data: {
      select_design_ids: selectDesignIds.value, // ok
      update_info: {
        design_id: designID,
        type: 'update',
        line_item: {
          size_id: variantItem.variantsID,
          size: variantItem.size,
          quantity: 1,
        }
      }
    }
  };
  try {
    const response = await axios.post('/api/carts/update_cart', addSiezdData);
    const cartInfo = response.data.data;
    designs.value = cartInfo?.designs;
    cost_details.value = cartInfo?.cost_details;
    // TODO
    updateSelectedDesigns(cartInfo);
  } catch (err) {
    HandleErrorResponse(err, () => reloadCartInfo());
  }
}

const goBackToDesign = () => {
  window.location.href = '/users/designs';
}


const designIsInavlid = (id) => {
  return invalidDesignIds.value.includes(id)
}

const designLabPageValidate = async(id) => {
  try {
    await axios.head(`/design_lab/${id}/edit`)
    window.location.href = `/design_lab/${id}/edit`;
  } catch(err) {
    swal({
      title: "提醒",
      text: '該設計已被刪除或下架，請重新選擇',
      dangerMode: true,
    }).then((_) => {
      window.location.reload();
    });
  }
}

const reloadCartInfo = () => {
  swal({
    title: "提醒",
    text: '產品資訊調整，請重新選購',
    dangerMode: true,
  }).then((_) => {
    window.location.reload();
  });
}


// 立即結帳 POST ，response資料
async function handleCheckout() {
  let stepData = { "step": "0" }
  if (selectDesignIds.value.length > 0) {
    axios.post('/api/carts/check_out', stepData)
    .then(() => {
      getCartInfo();
      openModal(1);
    })
    .catch(error => {
      const errorMsg = HandleErrorResponse(error);
      if (errorMsg == "user profile not completed") {
        openNoticeModal();
      } else { 
        reloadCartInfo();
      }
    })
  } else {
    swal({
      title: "提醒",
      text: '請勾選商品再進行結帳',
      dangerMode: true,
    })
  }
};

const payModal = ref();
function openModal(target) {
  if (target) {
    payModal.value.showTargetStep(target);
  } else {
    payModal.value.show();
  }
}

const noticeModal = ref();
function openNoticeModal() {
  noticeMessage.value = "請先完成填寫會員資料(姓名、地址)";
  noticeNextUrl.value = "/users/edit";
  noticeModal.value.show();
}

watch(() => designs.value, newV => {
  selectDesignIds.value = newV.filter(e => e.selected === true).map(e => e.design_id)

  // TODO: 整合控制Header badge顯示數量
  const designCount = newV.length > 99 ? '99+' : newV.length;
  document.querySelector("#cart-badge")?.querySelectorAll(".mk-float-badge").forEach(badgeEl => {
    badgeEl.innerHTML = designCount

    if (designCount == 0) badgeEl.style.visibility = 'hidden';
    else badgeEl.style.visibility = 'visible';
  })
})

onMounted(() => {
  getCartInfo();
});
</script>

<template>
  <div class="mk-container">
    <p class="text-center fs-2 my-4">購物清單</p>
    <!-- design cards -->
    <div v-for="(design, index) in designs" :key="design.design_id" class="card card-design">
      <div v-if="!designIsInavlid(design.design_id)" class="card-body p-4">
        <input v-model="design.selected" @change="selectDesign(design.design_id)" class="form-check-input design-checkbox" type="checkbox">
        <p class="card-design__title">
          {{ design.sku }}&emsp;{{ design.design_name }}&emsp;
        </p>
        <a href="javascript:void(0)" class="design-href" @click="designLabPageValidate(design.design_id)">編輯設計</a>
        <hr class="design-hr">

        <div class="row cart-panel">
          <div class="col-6 col-lg-3 my-3">
            <img width="250" class="img-fluid" :src="design.image_url">
          </div>
          <div class="col-6 col-lg-2 cart-panel__block">
            <div class="cart-panel__info">
              <p>原價 : <span class="text-red"> $ {{ design.product_price }} </span> </p>
              <p>顏色 : {{ design.color }}</p>
              <p class="mb-2">共 {{ design.total_quantity }} 件</p>
              <p class="cart-panel__info--badge"
                v-if="design.total_quantity >= 1 && design.total_quantity <= 4">版費 +{{ design.extra_price }}/件</p>
              <p class="cart-panel__info--badge"
                v-else-if="design.total_quantity >= 10">
                折扣
              </p>
            </div>
            <i @click="deleteDesign(index, design.design_id)" class="d-lg-none cart-panel__delete-card bi bi-trash"></i>
          </div>
          
          <div class="col-12 col-lg-4">
            <CounterGroup :lineItems="design.line_items" :variants="design.variants" :disabledUpdate="disabledUpdate" :hasOperatingBtn="true"  
              @updateItem="handleUpdatedItem(design.design_id, $event)" @deleteItem="deleteItem" @addSize="addSize(design.design_id, $event)" 
            />
          </div>
          <div class="col-12 col-lg-3 cart-panel__block cart-panel__block--mobile">
            <hr class="design-hr d-block d-lg-none">
            <p class="text-end m-0">小計：<span class="text-red ">$ {{ design.sub_total }}</span>
            </p>
            <i @click="deleteDesign(index, design.design_id)" class="d-none d-lg-block cart-panel__delete-card bi bi-trash"></i>
          </div>
        </div>
      </div>
      <div v-else class="card-body p-4">
        <p class="card-design__title">
          {{ design.sku }}&emsp;{{ design.design_name }}&emsp;
        </p>
        <hr class="design-hr">
        <div class="row cart-panel cart-panel--invalid">
          <div class="col-6 col-lg-3 my-3">
            <img width="250" class="img-fluid" :src="design.image_url">
          </div>
          <div class="col-6 col-lg-2 cart-panel__block">
            <p>顏色 : {{ design.color }}</p>
            <i @click="deleteDesign(index, design.design_id)" class="d-lg-none cart-panel__delete-card bi bi-trash"></i>
          </div>
          <div class="col-12 col-lg-2">
            <p>無規格</p>
          </div>
          <div class="col-12 col-lg-2">
            <p>該商品已下架或失效</p>
          </div>
          <div class="col-12 col-lg-3 cart-panel__block cart-panel__block--mobile">
            <i @click="deleteDesign(index, design.design_id)" class="d-none d-lg-block cart-panel__delete-card bi bi-trash"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- checkout section -->
    
    
    <div class="checkout-section">
      <div class="checkout-section__delivery-date">
        <p class="checkout-section__title">預計工作日&nbsp;<span>{{ cost_details.estimate_days }}&nbsp;天</span></p>
      </div>
      <div class="checkout-section__counter">
        <p class="checkout-section__title">購物車總數 <span class="float-end">共 {{ cost_details.total_quantity }} 件</span></p>
      </div>
      <div class="checkout-section__redirect-design">
        <button @click="goBackToDesign" class="mk-btn checkout-section-btn d-none d-sm-block"> &lt; 返回設計庫 </button>
      </div>
      <div class="checkout-section__cart-summary">
        <p class="checkout-section__title">金額明細</p>
        <div class="checkout-section__cart-summary--detail">
          <p>原價商品 <span class="float-end">{{ cost_details.original_design_price }}</span></p>
          <p>折扣商品 <span class="float-end">{{ cost_details.discount_design_price }}</span></p>
          <p>合併折扣
            <span v-if="cost_details.discount_number > 0" class="checkout-section-badge" >-{{ cost_details.discount_number }}%</span>
            <span class="float-end">{{ cost_details.combined_discount }}</span>
          </p>
          <p>小計 <span class="float-end">{{ cost_details.order_amount }}</span></p>
        </div>

        <!-- Button trigger modal -->
        <button @click="handleCheckout" class="mk-btn checkout-section-btn w-100 fs-5 d-none d-sm-block">立即結帳</button>

        <!-- Modal style="display: block;"-->
        <PayModal ref="payModal" :checkOutForm="checkOutForm" :paymentOptions="paymentOptions"
          :order_amount="cost_details.order_amount" :cities="cities" :shippingOptions="shippingOptions" />

        <NoticeModal ref="noticeModal" :message="noticeMessage" :nextUrl="noticeNextUrl" />
      </div>
      
      
    </div>
    <!-- 手機板 -->
    <div class="row d-sm-none">
      <div class="col">
        <button @click="goBackToDesign" class="mk-btn checkout-section-btn w-100"> 返回設計庫
        </button>
      </div>
      <div class="col">
        <button @click="handleCheckout" class="mk-btn checkout-section-btn w-100">立即結帳
        </button>
      </div>
    </div>
    
  </div>
</template>

<style lang="scss" scoped>

// TODO Counter SCSS 勿刪，整理counter
:deep .ls-5 {
  letter-spacing: 5px;
}

.text-red {
  color: #ED1C24;
}

// card
.card-design {
  margin: 1rem auto;
  border-width: 0px;
  border-radius: 15px;
  max-width: 1200px;
  &__title{
    display: inline;
    font-size: 1.125rem;
    color: #4D4D4D;
  }
  
  .design-checkbox {
    margin-top: 0;
    margin-right: 1.25rem;
    width: 20px;
    height: 20px;
  }
  .design-checkbox:checked {
    background-color: #EE7C26;
    border-color: #EE7C26;
  }
  .design-href{
    color: #1B09EA;
    letter-spacing: .25em;
    font-weight: 600;
    &:hover{
      color: #10067d;
    }
  }
  .design-hr {
    color: #4D4D4D;
    opacity: 1;
  }
}

.cart-panel{
  justify-content: center;
  align-items: center;
  &--invalid {
    p {
      color: #DBDBDB;
    }
  }
  &__info{
    &--badge{
      display: inline;
      border: 1px solid #EE7C26;
      border-radius: 0.375rem;
      padding: 0 0.5rem;
      font-size: 0.9rem;
      line-height: 2rem;
      color: #EE7C26;
    }
  }
  &__block{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__delete-card{
    padding: 0.75rem;
    background-color: #fff;
    font-size: 1.25rem;
    cursor: pointer;
    &:hover{
      background-color: #D9D9D9;
    }
  }
}

// checkout
.checkout-section{
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  margin: 1.5rem auto 1rem;
  padding: 0 1.5rem;
  max-width: 1200px;
  > div{
    margin-bottom: 0.5rem;
    width: 35%;
  }
  &__title{
    margin: 0;
    font-size: 1.375rem;
  }
  &__delivery-date{
    height: 30px;
  }
  &__cart-summary{
    padding-top: 1rem;
    border-top: 1px solid #999999;
    &--detail{
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      font-size: 1rem;
      > p{
        margin: 0.25rem 0 0.5rem;
      }
    }
  }
}
.checkout-section-btn{
  border-radius: 0;
  letter-spacing: 5px;
  margin-top: 1rem;
}
.checkout-section-badge{
  margin-left: 0.5rem;
  padding: 0px 10px;
  border-radius: 20px;
  color: #ffffff;
  background-color: #EE7C26;
  font-weight: 700;
}

@media screen and (max-width: 991px){
  .mk-container{
    padding: 0 0.75rem;
  }
  .cart-panel{
    justify-content: center;
    align-items: center;
    &__block{
      justify-content: space-between;
      &--mobile{
        display: block;
      }
    }
    &__delete-card{
      padding: 0.35rem;
    }
  }
  .checkout-section{
    > div{
      width: 40%;
    }
  }
}

@media screen and (max-width: 767px){
  .checkout-section{
    > div{
      width: 48%;
    }
  }
}

@media screen and (max-width: 575px){
  .checkout-section{
    flex-direction: column;
    margin-bottom: 0rem;
    > div{
      margin-bottom: 1rem;
      width: 100%;
    }
    &__delivery-date{
      height: auto;
      > p{
        span{
          float: right;
        }
      }
    }
    .checkout-section__cart-summary{
      margin-bottom: 0;
    }
  }
  .checkout-section-btn{
    border-radius: 0.375rem;
    padding: 0.3rem 0.75rem;
    font-size: 1.25rem;
  }
}
</style>