const sharedLinkSelector = ".js-shared-link";

class SharedLink {
  constructor(sharedLinkEl) {
    this.sharedLinkEl = sharedLinkEl;

    const linkUrl = this.sharedLinkEl.dataset.sharedLinkUrl;
    const title = this.sharedLinkEl.dataset.sharedLinkTitle;
    const text = this.sharedLinkEl.dataset.sharedLinkText;

    const shareData = {
      title: title || "",
      text: text || "",
      url: linkUrl || "",
      // files: null
    }
    
    sharedLinkEl.addEventListener("click", () => {
      // 只支援 https
      if (navigator.share) {
        this.handleNavigatorShare(shareData);
      } else {
        this.handleNotSupportNavigatorShare(shareData);
      }
    })
  }

  async handleNavigatorShare(shareData) {
    try {
      await navigator.share(shareData);
    } catch (err) {
      // 使用者拒絕分享或發生錯誤
      const { name } = err;
      if (name === 'AbortError') {
        console.log('您已取消分享此訊息')
      } else {
        console.log('發生錯誤', err);
      }
    }
  }

  handleNotSupportNavigatorShare(shareData) {
    // TODO: 實作替代功能
    console.log("not support navigator share!")
  }
}

export default function NewSharedLinks() {
  document
    .querySelectorAll(sharedLinkSelector)
    .forEach((el) =>{
      new SharedLink(el);
    });
}
