<template>
  <div class="px-3">
    <div v-for="(item) in line_items" :key="item.name">
      <div class="d-flex justify-content-between">
        <p class="d-flex align-items-center ">
          <span class="mk-text-bg-light py-1 fw-semibold text-center" >{{ item.size }}</span>
          <span v-if="item.surcharge != 0" class="markUp ms-2">+{{ Math.floor(item.surcharge) }}</span>
        </p>
        <div class="btn-group fw-semibold">
          <button @click="decrementItem(item)" type="button" 
            class="btn btn-counter px-3 inner-text" style="--bs-btn-font-weight: 900;">-</button>
          <p class="mx-0 my-0 w-quantity">{{ item.quantity }}</p>
          <button @click="incrementItem(item)" type="button" 
            class="btn btn-counter px-3 inner-text rounded-end" style="--bs-btn-font-weight: 900;">+</button>
        </div>
      </div>
    </div>
  </div>
</template> 

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
// import { Debounce } from "~/javascripts/common.js";

const props = defineProps({
  line_items: Array,
});

const emit = defineEmits(['IncrementItem', 'DecrementItem']);

let MIN_COUNT = 0;

let setItem = {};

const incrementItem = (item) => {
  setItem = { ...item };
  emit('IncrementItem', item);
  setItem.quantity++;
}

const decrementItem = (item) => {
  if (item.quantity > 1) {
    setItem = { ...item };
    emit('DecrementItem', item);
    setItem.quantity--;
  } else if (item.quantity == 1) {
    emit('DecrementItem', item);
  }
}

</script>

<style lang="scss" scoped>
.markUp {
  font-size: 0.8rem;
  
}

button.markUp:hover {
  background-color: #ff6f00;
  color: #ffffff;
}

.inner-text {
  font-size: 20px;
  line-height: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-group {
  align-items: center;
}

.btn-counter {
  --bs-btn-color: #000;
  --bs-btn-bg: #D9D9D9;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D9D9D9;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-btn-disabled-opacity: 1;
}

.btn-x {
  background-color: transparent;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  padding-left: 10px;
}

.mk-text-bg-light {
  color: #000;
  background-color: #D9D9D9;
  width: 50px;
  font-size: 0.9rem;
}

.w-quantity {
  width: 34px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background-color: #fff;
}
</style>