<template>
  <!-- 遍歷 items 陣列中的每個尺寸項目，傳遞給 Counter 元件 -->
  <Counter :line_items="line_items" @IncrementItem="IncrementItem" @DecrementItem="DecrementItem" />
  <!-- 顯示總計數量 -->
  <div class="mt-3">
    此為訂製商品,製作時間10-14天
  </div>
</template>


<script setup>
import { ref, computed, defineEmits, defineProps, watch, watchEffect, onMounted, onUpdated } from 'vue';
import Counter from "./Counter.vue";
import { Modal } from "bootstrap";

const props = defineProps({
  line_items: Array,
});

const emit = defineEmits(["IncrementItem" , 'DecrementItem']);

const IncrementItem = (item) => {
  emit('IncrementItem', item )
}

const DecrementItem = (item) => {
  if (item.quantity > 0) {
    emit('DecrementItem', item)
  }
}

</script>
<style lang="scss" scoped>

.form-select {
  width: 120px;
  margin-right: 10px;
}
.submit-block{
  display: flex;
  justify-content: space-between;
  .modal-total-price{
    display:inline-block;
    height:auto; 
    line-height: 1;
    font-weight: 900;
  }
  .add-to-shopping-cart{
    margin-left:20px ;
    padding: 0.25rem 2rem;
    border-radius: 20px;
  }
 
}
</style>