import { defineStore } from 'pinia'
import axios from 'axios';
import {toastr_sucess, toastr_error} from '~/javascripts/toastr.js';
import { HandleErrorResponse } from "~/javascripts/utils.js"

export const useResourceFormStore = defineStore('resourceFormStore', {
  state: () => {
    return {
      ready:     false,
      saving:    false,
      saveEmit: false,
      resource:  {},
      api:       null
    }
  },
  getters: {
    saveBtnString() {
      return this.saving ? "儲存中" : "儲存";
    }
  },
  actions: {
    async getResource() {
      let resp = {};
      try {
        resp = await axios.get(`${this.api}/get_store_page`);

        this.ready = true;
        return resp.data;
      } catch(err) {
        HandleErrorResponse(err);
      }
    },

    async saveResource(params) {
      let resp = null;
      this.saving = true;
      try {
        resp = await axios.put(`${this.api}/update_store_page`, {store_page: params});

        toastr_sucess("儲存成功");
        this.saveDone();

        return resp;
      } catch (err) {
        HandleErrorResponse(err);
        this.saveDone();

        return null;
      }
    },

    saveDone() {
      setTimeout(() => {
        this.saving = false;
      }, 2000);
    },

    setReady() {
      if(!this.ready) {
        this.ready = true;
      }
    },

    setSaving() {
      if(!this.saving) {
        this.saving = true;
      }
    },

    save() {
      // 不用理會是true或者是false，用來觸發是否有要執行save動作
      this.saveEmit = !this.saveEmit;
    }

  }
})