

const subMenuSelector = ".js-sub-menu";
const defaultSubMenuExpendedClass = "menu-content-item-with-sub--expended";

export default class SubMenu {
  constructor() {
    document
    .querySelectorAll(subMenuSelector)
    .forEach((subMenuEl) =>{
      subMenuEl.addEventListener("click", SubMenu.clickHandler.bind(this));
    });
  }

  static clickHandler(e){
    this.el = e.currentTarget;
    if (e.target.parentNode == this.el) {
      e.preventDefault();
    }
    
    this.setVariables();
    this.setSubMenuVisibility();
  }

  setVariables() {
    const {
      subMenuExpendedClass,
    } = this.el.dataset;

    if (typeof subMenuExpendedClass === "string"){
      this.subMenuExpendedClass = subMenuExpendedClass;
    } else {
      this.subMenuExpendedClass = defaultSubMenuExpendedClass;
    }
  }

  setSubMenuVisibility() {
    this.el.classList.toggle(this.subMenuExpendedClass);
  }

};