import { createApp } from 'vue'
import WithdrawalModalContent from "./withdrawal_modal_content.vue";
import DetailModalContent from './detail_modal_content.vue';

export default function InitUserWalletPage() {
  const pageID = "users-wallet" 
  const page   = document.getElementById(pageID)
  if (!page) return false;
  
  document.addEventListener("DOMContentLoaded", () => {
    verifyWithdraw();
  });

  // 提款modal
  const withdrawalModalContentSelector = "#js-withdrawal-modal-content"
  if (!document.querySelector(withdrawalModalContentSelector)) return false;
  const WithdrawalModalContentApp = createApp(WithdrawalModalContent);
  WithdrawalModalContentApp.mount(withdrawalModalContentSelector);

  // 明細modal
  const detailModalContentSelector = "#js-detail-modal-content"
  if (!document.querySelector(detailModalContentSelector)) return false;
  const DetailModalContentApp = createApp(DetailModalContent);
  DetailModalContentApp.mount(detailModalContentSelector);
}

function verifyWithdraw () {
  const withdrawErrorBtn = document.querySelector('.js-withdraw-error');
  if(withdrawErrorBtn) {
    withdrawErrorBtn.addEventListener('click', () => {
      const errorMsgExist = document.querySelector('.wallet-balance__error')
      console.log(errorMsgExist)
      if (!errorMsgExist) {
        const errorMsg = document.createElement('div');
        errorMsg.innerText = '錢包餘額需大於$300';
        errorMsg.classList.add('wallet-balance__error')
        withdrawErrorBtn.parentNode.appendChild(errorMsg)
      }
    })
  }
}