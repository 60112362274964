export default function InitProductCategoryPage() {
  const pageID = "product-category-list" 
  const page   = document.getElementById(pageID)
  if (!page) return false;

  document.addEventListener("DOMContentLoaded", () => {
    // initProductSearchResetIcon();
    // initBackButton();
  });
}

// function initProductSearchResetIcon() {
//   const resetIcon = document.getElementById("js-product-search-reset");
//   const input     = document.getElementById("js-product-search-input");

//   const showResetIcon = ()=> {
//     console.log('asjdiajsid')
//     resetIcon.style.display = "block";
//   }

//   const hideResetIcon = ()=> {
//     resetIcon.style.display = "none";
//   }

//   input.addEventListener("input", ()=>{
//     if(input.value !== "") {
//       showResetIcon();
//     } else {
//       hideResetIcon();
//     }
//   });

//   resetIcon.addEventListener("click", ()=>{
//     input.value = "";
//     input.dispatchEvent(new Event("input"));
//   });

//   input.dispatchEvent(new Event("input"));
// }

// function initBackButton() {
//   const btn = document.getElementById("js-product-back");

//   btn.addEventListener("click", ()=>{
//     window.history.go(-1);
//     return false;
//   });
// }