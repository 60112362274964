<template>
  <div class="split-left-right-block store-page__notify">
    <div class="left-block">
      <div class="switch-toggle-block">
        <h3>銷售頁通知</h3>
        <div class="form-check form-switch">
          <input v-model="isNotifyRequired" class="form-check-input toggle-input" type="checkbox" role="switch" id="notify-toggle"/>
          <label class="form-check-label" for="notify-toggle"></label>
        </div>
      </div>
      <div class="message-tips">
        <p>啟用銷售頁通知，會固定顯示在最上方。</p>
        <a href="#">說明</a>
      </div>
    </div>
    <div class="right-block">
      <div :class="isNotifyRequired ? 'form-block required' : 'form-block' ">
        <label class="form-block__label" for="landing-notice">文字</label>
        <!-- validation vue component化 -->
        <textarea 
          type="text" 
          id="landing-notice" 
          class='form-block__textarea js-textarea-adapt'     
          placeholder="例: 春節休假公告 1/20~1/31"
          spellcheck="false"
          @input="textareaInput"
          v-model="notifyContent"
        ></textarea>
        <div v-if="inputValidationRequired" class="users-block__feedback">{{ '此欄位必填' }}</div>
        <div v-if="inputValidationLength" class="users-block__feedback">{{ '最多20個字' }}</div>
        <div class="mk-form-feedback mk-form-feedback--hide users-block__feedback"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits,defineExpose, computed, reactive, watch, watchEffect } from "vue";
import {Debounce} from "~/javascripts/common.js";

  const props = defineProps(["toggle","content","validation"]);
  const emit = defineEmits(["update:toggle","update:content","update:validation"]);

  const isNotifyRequired = ref(props.toggle);
  const notifyContent = ref(props.content)
  const inputValidationRequired = ref(false);
  const inputValidationLength = ref(false);
  
  watch(() => props.toggle,(newValue)=>{
    isNotifyRequired.value = newValue
  })
  
  watch(isNotifyRequired,(newValue) => {
    emit('update:toggle', newValue)
    if(newValue){
      textareaInput();
    } else{
      inputValidationRequired.value = false;
      inputValidationLength.value = false;
      emit('update:validation', true)
    }
  })
  
  watch(()=>props.content, (newValue)=>{
    if(newValue) notifyContent.value = newValue;
  })

  const textareaInput = Debounce(() => {
    emit('update:content', notifyContent.value)
    handleInputValidation();
  },100);

  const handleInputValidation = (isSubmit = false)=>{
    emit('update:validation', true);
    inputValidationRequired.value = false;
    inputValidationLength.value = false;
    if(isNotifyRequired.value){
      if(notifyContent.value === '') {
        inputValidationRequired.value = true;
        if(isSubmit) scrollToValid();
        emit('update:validation', false)
      } 
      if(notifyContent.value.length > 20) {
        inputValidationLength.value = true;
        emit('update:validation', false)
      }
    }
  }

  function scrollToValid(){
    const target =  document.querySelector("label[for='landing-notice']");
    if(!isElementInViewport(target)) target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  defineExpose({ 
    handleInputValidation: handleInputValidation
  });
</script>