import axios from "axios"
import { HandleErrorResponse } from "~/javascripts/utils.js"

export default function InitUserStoreProducts() {
  const pageID = "users-store-products" 
  const page   = document.getElementById(pageID)
  if (!page) return false;
  document.addEventListener("DOMContentLoaded", () => {
    initFilter();
    initSearch();
    deleteItem();
  });
}

function initFilter(){
  const filteStatusBtns = document.querySelectorAll(".js-search-status");
  filteStatusBtns.forEach(btn => {
    btn.addEventListener('mousedown',function(e){
      // e.preventDefault();
      //TODO params 參數傳遞修改
      console.log('click')
      document.querySelector("#search-form input[name='search_status']").value = this.value.toLowerCase();
    })
  });
}

function initSearch(){
  const resetIcon = document.getElementById("js-item-search-reset");
  const input     = document.getElementById("js-item-search-input");
  const showResetIcon = ()=> {
    resetIcon.style.display = "block";
  }

  const hideResetIcon = ()=> {
    resetIcon.style.display = "none";
  }

  input.addEventListener("input", ()=>{
    if(input.value !== "") {
      showResetIcon();
    } else {
      hideResetIcon();
    }
  });

  resetIcon.addEventListener("click", ()=>{
    input.value = "";
    input.dispatchEvent(new Event("input"));
  });

  input.dispatchEvent(new Event("input"));
}


function deleteItem(){
  
  const removeItemModal = document.querySelector('.js-modal-remove-store-product');

  if(removeItemModal){
    removeItemModal.addEventListener('show.bs.modal',(e)=>{
      const relatedDiv = e.relatedTarget;
      const storeProductID = relatedDiv.getAttribute('data-bs-store-product-id');
      const btn = document.getElementById('js-delete-btn')
      btn.onclick = ()=>{
        const originalUrl = window.location.href;
        axios.delete(`/api/store_products/${storeProductID}`)
          .then(()=>{
            window.location.href = originalUrl;
          }).catch((err)=>{
            HandleErrorResponse(err);
          })
      }
    })
  }
}
