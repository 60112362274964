<template>
  <div class="split-left-right-block store-page__layout-setting">
    <div class="left-block">
      <h3>銷售頁面設置</h3>
      <div class="message-tips">
        <p>圖片: 增加您想要的圖片</p>
        <p>影片: 開放Youtube影片嵌入</p>
        <p>文字編輯器: 可以自由添加文字</p>
        <p class="last-p">您可以自由添加圖片，影片或文字，選擇您想要放的位置，編輯展示您的銷售頁面。</p>
        <a href="#">說明</a>
      </div>
      <a :href="landingSalesUrl" class="direct-to-landing btn" target="_blank">
        <img :src="GetImageUrl('link_blank.svg')" alt="link">
        <span>查看銷售頁</span>
      </a>
    </div>
    <div class="right-block">
      <div class="form-block required mb-3">
        <label class="form-block__label">店鋪頁面展示 <span class="input-tip">在此建立主要的銷售頁內容,幫助消費者理解產品。</span></label>
        <div class="layout-blocks px-4">
          <div v-if="layoutBlocks.length == 0 || !layoutBlocksIsNotEmpty" class="default">

          </div>
          <div v-else>
            <draggable
              class="layout-group"
              :list="layoutBlocks"
              item-key="order"
              handle=".handle"
              ghost-class="ghost"
              animation=200
              @update="updateDraggable"
            >
              <template #item="{ element }">
                <div v-if="String(element._destroy) != '1'" class="layout-group-item">
                  <div class="layout-group-item-header">
                    <a class="btn layout-group-item-handler handle">
                      <img :src="icons.gripVertical">
                    </a>

                    <div class="layout-group-item-icon-wrap">
                      <a class="btn layout-group-item-icon" @click="editLayoutBlock(element)">
                        <img :src="icons.pencil">編輯
                      </a>
                      <a class="btn layout-group-item-icon" @click="removeBlock(element)">
                        <img :src="icons.trash">刪除
                      </a>
                    </div>
                  </div>
                  
                  <div class="layout-group-item-content">
                    <div v-if="element.block_type === 'image'" style="text-align: center;">
                      <img v-if="element.content" :src="element.content" style="width: 100%;" loading="lazy">
                      <img v-else :src="defaultContent.image" class="mka-svg--gray" style="width: 50%; vertical-align: ;;" loading="lazy">
                    </div>
                    <div v-else-if="element.block_type === 'video'" class="text-center iframe-container">
                      <iframe v-if="element.content" :src="element.content" width="560" height="315" frameborder="0" allowfullscreen />
                      <iframe v-else :src="defaultContent.video" width="560" height="315" frameborder="0" allowfullscreen />
                    </div>
                    <div v-else-if="element.block_type === 'textEditor'">
                      <div v-if="element.content" v-html="element.content" />
                      <div v-else v-html="defaultContent.textEditor" />
                    </div>
                    <div v-else>Something Wrong!</div>
                  </div>
                </div>
              </template>
            </draggable>
            <LayoutBlockModal
              ref="layoutBlockModal"
              :layoutBlock="layoutBlock"
              @updateLayoutBlock="renewLayoutBlocks"
            />
          </div>
        </div>
        <!--TODO : validation vue component化-->
        <div v-if="layoutBlocksValidationRequired" class="users-block__feedback">{{'此欄位必填'}}</div>
        <div class="users-block__feedback"></div>
      </div>
      <div class="add-layout-block">
        <div class="title">
          新增區塊
        </div>

        <div class="btns-wrapper">
          <a v-for="setting in addBlockBtns" :key="setting.type" class="btn users-solid-btn" @click="setting.onclick">
            {{ setting.label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits, defineExpose, computed, reactive, watch } from 'vue'
  import { GetImageUrl } from "~/javascripts/utils.js";
  import LayoutBlockModal from  "./store_page_layout_block_modal.vue"
  import draggable from 'vuedraggable';
  import '@wangeditor/editor/dist/css/style.css';
  
  // props data
  const props = defineProps({
    storePageID: String,
    storePageLayoutBlocks: Array,
    layoutBlocksValidation: Boolean,
    landingSalesUrl: String,
  })

  const emit = defineEmits(["update:storePageLayoutBlocks","update:validation"]);

  // default setting
  const addBlockBtns = reactive([
    {label: "圖片", type: "image", onclick: () => addBlock('image')},
    {label: "影片", type: "video", onclick: () => addBlock('video')},
    {label: "文字編輯器", type: "textEditor", onclick: () => addBlock('textEditor')},
  ])

  const icons = reactive({
    gripVertical: GetImageUrl('admin/product/grip-vertical.svg'),
    pencil: GetImageUrl('admin/product/pencil.svg'),
    pencilSquare: GetImageUrl('admin/product/pencil-square.svg'),
    trash: GetImageUrl('admin/product/trash.svg'),
    image: GetImageUrl('admin/product/image.svg'),
    video: GetImageUrl('admin/product/camera-video.svg'),
  })

  const defaultContent = reactive({
    image: icons.image,
    video: "https://www.youtube.com/embed/GhzuXHj8FBk",
    textEditor: "<h5><strong>測試標題</strong></h5><p>這個內容是測試使用，請點擊編輯後，修改為您的內容</p>"
  })

  const storePageID = computed(() => props.storePageID)

  const landingSalesUrl = computed(() => props.landingSalesUrl)

  const layoutBlocks = ref([]) // .sort((blockA, blockB)=> blockB.order-blockA.order)
 
  const layoutBlocksIsNotEmpty = ref(false) // destroy:'1' version

  const layoutBlocksValidationRequired = ref(false);// validation

  let layoutBlock = ref({}); // selected layout block
  const layoutBlockModal = ref()

  watch(() => props.storePageLayoutBlocks, (newValue)=>{
    layoutBlocks.value = newValue;
    if(props.storePageLayoutBlocks.length > 0){
      layoutBlocksExist()
    }

  },{deep:true});

  watch(layoutBlocks, (newValue)=>{
    emit('update:storePageLayoutBlocks', newValue)
  },{deep:true});


  // functions
  function addBlock(blockType) {
    const acceptedTypes = ["image", "video", "textEditor"];
    const count = layoutBlocks.value.length;
    const defaultContent = "";

    if (acceptedTypes.includes(blockType)) {
      layoutBlocks.value.push({
        // id: storePageID.value,
        order: count,
        block_type: blockType,
        content: defaultContent,
      })

      handleBlockSort();
      layoutBlocksIsNotEmpty.value = true;
      layoutBlocksValidationRequired.value = false;
      emit('update:validation', true);
    } else {
      console.log("Error layout block type");
    }
  }

  function removeBlock(deleteBlock) {
    deleteBlock["_destroy"] = "1";
    layoutBlocksExist();
    // infoBlocks.value.splice(layoutBlock.order,1)
    // handleBlockSort();
  }
 
  function handleBlockSort() {
    layoutBlocks.value.forEach((block, index) => {
      block.order = index;
    })
  }
  function updateDraggable(evt) {
    handleBlockSort();
  }

  function layoutBlocksExist(){
    let exist = false;
    layoutBlocks.value.forEach(block=>{
      if((!block._destroy)){
        exist = true
      }
    });
    layoutBlocksIsNotEmpty.value = exist;
    layoutBlocksValidationRequired.value = !exist;
    emit('update:validation', exist)
  }
  
  function editLayoutBlock(editBlock) {
    const supportedType = ["image", "video", "textEditor"]

    if (supportedType.includes(editBlock?.block_type)) {
      layoutBlock.value = editBlock;
      layoutBlockModal.value.show();
     
    } else {
      console.log("The layoutBlock not supported");
    }
  }

  function renewLayoutBlocks(newBlock) {
    let target = layoutBlocks.value.find((source) => source.order == newBlock.order)
    target.content = newBlock.content;

    // reset
    layoutBlock.value = {};
  }

  defineExpose({ 
    layoutBlocksExist: layoutBlocksExist
  });
</script>

<style scoped lang="scss">
  $mk-orange:#ee7c26;
  
  
  .store-page{
    &__layout-setting{
      .direct-to-landing{
        width: 9rem;
        color: #fff;
        background-color: $mk-orange;
        border-color:$mk-orange ;
        margin: 1rem 0;
        padding: 0.2rem 0.2rem 0.3rem;
        img{
          width: 26px;
          height: 26px;
          vertical-align: text-bottom;
          padding-bottom:1px ;
          margin-right:5px ;
        }
      }
      .layout-blocks{
        width: 100%;
        :deep p{
          line-height: 22px;
          font-size: 1rem;
        }
        :deep ul{
          padding-left: 2rem;
          li {
            margin: 0.5rem 0 0.5rem 0.5rem;
            list-style-type:disc;
            overflow-wrap: break-word;
            word-break: break-word;
          }
        }
        :deep ol{
          padding-left: 1.75rem;
          li {
            margin: 0.5rem 0 0.5rem 0.5rem;
            list-style-type:decimal;
            overflow-wrap: break-word;
            word-break: break-word;
          }
        }
        .default{
          position: relative;
          background-color: #fff;
          width: 100%;
          height: 240px;
        }
        .default::before{
          content: '沒有內容,請點擊下方[ 新增區塊 ]';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          line-height: 1.5;
          font-size: 1.375rem;
          @media screen and (max-width: 1080px){
            font-size: 1.15rem;
          }
        }
      }
      .add-layout-block{
        margin-top: 20px;
        padding: 0.75rem 1rem;
        width: 100%;
        min-height: 100px;
        background-color: #fff;
        .btns-wrapper{
        padding: 20px 0 5px;
        display: flex;
        flex-wrap: wrap ;
        gap: 3rem;
          @media screen and (max-width: 1080px){
            gap: 1rem;
          }
        }
      }
      .iframe-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-top: 56.25%; /* 16:9 aspect ratio (height/width) */
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 768px){
    .store-page{
      &__layout-setting{
        .add-layout-block{
          .btns-wrapper{
            gap: 2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 510px) {
    .store-page{
      &__layout-setting{
        .add-layout-block{
          .btns-wrapper{
            gap: 0.5rem;
            justify-content: start;
          }  
        }
        .btns-wrapper :not(.btn:last-child){
          width: 25%;
        }
      }
    }
  }

  //copy admin的scss
  .layout-group-item {
    margin: 1rem 0;
    background: #ECECEC;

    .layout-group-item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 2rem;
    }

    .layout-group-item-content {
      padding: 1rem;
      background: white;
      overflow: hidden;
    }
  }

  .layout-group-item-handler {
    --bs-btn-border-width: unset;
  }

  .layout-group-item-icon {
    --bs-btn-border-width: unset;

    img {
      margin-top: -.2rem;
      margin-right: .5rem;
    }
  }
  .mka-svg {
    &--white {
      filter: brightness(0) invert(1);
    }

    &--gray {
      filter: brightness(0) invert(0.9);
    }
  }
</style>