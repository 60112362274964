import $ from "jquery";
window.$ = $;
import "@select2/dist/css/select2.min.css";
import select2 from 'select2';
select2();

export default function newDektopSelect() {
  $('.form-block .js-select2-dektop').each((index,el)=>{
    let $el = $(el);
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowWidth > 768) {
      $el.removeClass('form-block__select')
      $el.addClass('form-block__select2')
      $el.select2({
        placeholder : '選擇商店類型',
        search: false,
        minimumResultsForSearch : Infinity,
        width: '100%'
      })
    } else {
      $el.removeClass('form-block__select2')
      $el.addClass('form-block__select')
    }
  })
}
