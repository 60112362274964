// .mk-dropdown as new dropdown class name
const dropdownSelector = ".mk-dropdown";
const overflowHardShowClassName = "js-dropdown-overflow-hard-show";
const bodySelector = "body";
const dropdownMenuSelector = ".dropdown-menu";

class OverflowHardShow {
  constructor(dropdownEl) {
    // source el
    this.dropdownEl = dropdownEl;
    // target el
    this.bodyEl = document.querySelector(bodySelector)
    // source dropdown-menu
    this.dropdownMenu = this.dropdownEl.querySelector(dropdownMenuSelector)

    // show dropdown-menu and bind to body
    this.dropdownEl.addEventListener('show.bs.dropdown', event => {
      this.bodyEl.append(this.dropdownMenu.parentNode.removeChild(this.dropdownMenu));
    })

    // hidden dropdown-menu and bind back to source
    this.dropdownEl.addEventListener('hidden.bs.dropdown', event => {
      this.dropdownEl.append(this.dropdownMenu.parentNode.removeChild(this.dropdownMenu))
    })
  }
}

export default function NewDropdown() {
  document
    .querySelectorAll(dropdownSelector)
    .forEach((el) =>{
      if (el.classList.contains(overflowHardShowClassName) ) new OverflowHardShow(el);
    });
}
