import newDektopSelect from '~/javascripts/app/dektop_select2.js';
import { GetImageUrl} from "~/javascripts/utils.js"
import swal from "sweetalert";

export default function InitUserStoreIndexPage() {
  const pageID = "users-stores-new" 
  const page   = document.getElementById(pageID)
  if (!page) return false;
  document.addEventListener("DOMContentLoaded", () => {
    newDektopSelect();
    uploadPreviewImg();
    checkApplyBlock();
  });
}

function uploadPreviewImg() {
  const imagePreviewInput = document.querySelector('.js-image-preview-input')

  imagePreviewInput.addEventListener('change',(e)=>{
    const file = e.target.files[0]
    if(file){
      var reader = new FileReader();

      reader.onload = function (readerEvent) {
        refreshCoverImgLabel(readerEvent.target.result) 
      };

      reader.readAsDataURL(file); // 讀取圖片文件，轉換成 Data URL
    }
  })
}

function refreshCoverImgLabel(img_result) {
  const imagePreview = document.querySelector('.js-image-preview');
  imagePreview.innerHTML = ''; // 清空預覽區域
  imagePreview.innerText = '店鋪封面';
  let imageElement = document.createElement('img');
  imageElement.src = img_result;
  imageElement.alt = 'PreviewImage';
  imageElement.style.width = '50%';
  imagePreview.appendChild(imageElement);
  let iElement = document.createElement('i');
  iElement.classList.add("bi","bi-trash3", "js-delete-preview-img");
  iElement.addEventListener('click', clearPreviewImage)
  imagePreview.appendChild(iElement);
}

function clearPreviewImage(e) {
  e.preventDefault();
  clearCoverImgInput();
  const imagePreview = document.querySelector('.js-image-preview');
  imagePreview.innerHTML = ''; // 清空預覽區域
  imagePreview.innerText = '店鋪封面';
  let imageElement = document.createElement('img');
  imageElement.src = GetImageUrl('index/pic-icon.png')
  imagePreview.appendChild(imageElement);
  let divElement = document.createElement('div');
  divElement.textContent = '點擊上傳';
  imagePreview.appendChild(divElement);
}

function clearCoverImgInput() {
  const originalInput = document.querySelector('.js-image-preview-input');
  originalInput.value = null;
}

function checkApplyBlock(){
  let limitFlag = false
  const checkboxbtn= document.querySelector('.js-checkbox-btn')
  checkboxbtn.onclick = (e)=>{
    if(!limitFlag) {
      swal({
        title: "提醒",
        text: '請先完整閱讀申請說明',
        dangerMode: true,
      })
      e.preventDefault();
      return;
    }
    if(checkboxbtn.checked) {
      checkboxbtn.value = 'accept'
    } else {
      checkboxbtn.value = ''
    }
  }

  const checkApply = document.querySelector('.js-check-apply');
  checkApply.addEventListener('click',()=>{
    limitFlag = true;
    checkboxbtn.click();
  }, { once: true })
}







