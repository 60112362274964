const zoomImageAnimationHandler = (e)=>{zoomImageAnimation(e.currentTarget)};// 照片動畫zoom in核心事件

const fullscreenContainer = document.querySelector('.js-fullscreen-container');
const overlay = document.querySelector('.js-fullscreen-container__overlay');
const zoomedBlock = document.querySelector('.js-zoomed-block');

//全螢幕照片動畫 function
function zoomImageAnimation(clickObject){
  console.log('zoom')
  fullscreenContainer.style.display = "block";
  // original img 大小、座標
  const w = clickObject.querySelector('img').width;
  const h = clickObject.querySelector('img').height;
  const x = clickObject.querySelector('img').getBoundingClientRect().left;
  const y = clickObject.querySelector('img').getBoundingClientRect().top;

  // 照片
  const imgSrc = clickObject.querySelector('img').src;
  zoomedBlock.querySelector('img').src = imgSrc;

  overlay.style.display = 'block';
  document.body.style.overflow = 'hidden'; // Disable scrolling on the body

  // 定位初始元素位置
  zoomedBlock.style.width = `${w}px`;
  zoomedBlock.style.display = 'block';
  zoomedBlock.style.left = `${x}px`;
  zoomedBlock.style.top = `${y}px`;
  zoomedBlock.style.transition= '350ms';

  // 找尋當前畫面中心點
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const centerX = viewportWidth / 2;
  const centerY = viewportHeight / 2;

  // 倍率、位移動畫參數
  let scaleWidth = (window.innerWidth > 768) ? 768 : window.innerWidth * 0.9;
  const scaleRatio = scaleWidth / w; // scale倍率
  const translateOffsetX = centerX - x - (scaleWidth / 2);
  const translateOffsetY = centerY - y - (scaleWidth / 2);
  zoomedBlock.style.transform = `translate(${translateOffsetX }px, ${translateOffsetY}px) scale(${scaleRatio})`;

  zoomedBlock.addEventListener('transitionend', function() {
    fullscreenContainer.onclick =  function () {
      console.log('this is animations')
      zoomedBlock.style.transition= '350ms';
      zoomedBlock.style.transform = `translate(0%, 0%) scale(1)`;
      zoomedBlock.addEventListener('transitionend', function() {
          // 过渡结束后执行其它操作
          zoomedBlock.style=""
          document.body.style.overflow = 'auto'; // 启用主体滚动
          fullscreenContainer.style.display = 'none';
        }, { once: true }
      );
    };
  }, { once: true });
}

export { zoomImageAnimationHandler }
