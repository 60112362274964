import { Dropdown } from "bootstrap";
import { isTime } from "validator";

// dropdown as select
const dropdownSelectSelector = ".js-dropdown-select";
const dropdownSelectItemSelector = ".js-dropdown-select__item"
const dropdownSelectButtonSelector = ".js-dropdown-select__button"
const dropdownSelectValueSelector = ".js-dropdown-select__value"
const dropdownSelectTextSelector = ".js-dropdown-select__text"

class DropdownSelect {
  constructor(dropdownEl) {
    // source el
    this.dropdown         = dropdownEl;
    this.dropdwonDefault  = this.dropdown.getAttribute('data-value-default')
    this.dropdownItems    = this.dropdown.querySelectorAll(dropdownSelectItemSelector);
   
    this.dropdownValue    = this.dropdown.querySelector(dropdownSelectValueSelector);
    this.dropdownText     = this.dropdown.querySelector(dropdownSelectTextSelector);
      
    if(this.dropdownValue) this.dropdownValue.value = this.initDefaultValue().getAttribute('data-value')
    if(this.dropdownText)  this.dropdownText.textContent = this.initDefaultValue().getAttribute('data-value-text')
 
    this.initClick();
  }

  initDefaultValue() {
    if(this.dropdwonDefault) {
      return Array.from(this.dropdownItems).find((item) => item.getAttribute('data-value') === this.dropdwonDefault) || this.dropdownItems[0]
    } else {
      return this.dropdownItems[0]
    }
  }

  initClick() {
    if(!this.dropdownValue || !this.dropdownText) {
      console.log('less value or text');
      return ;
    }
    this.dropdownItems.forEach(el => {
      el.addEventListener('click', () => {
        if(this.dropdownValue.value !== el.getAttribute('data-value')) {

          this.dropdownValue.value = el.getAttribute('data-value');
          this.dropdownText.textContent = el.getAttribute('data-value-text');

          // replace DOM element
          // // remove old DOM element, retain class list
          // const oldDropdownText = this.dropdown.querySelector(dropdownSelectTextSelector);
          // let classArray = []
          // if(oldDropdownText) {
          //   classArray = oldDropdownText.classList;
          //   oldDropdownText.remove();
          // }

          // add original DOM element, change text content
          // const newDropdownText = document.createElement('div');
          // classArray.forEach(className => {
          //   newDropdownText.classList.add(className);
          // });
          
          // newDropdownText.textContent = el.getAttribute('data-value-text');
          // this.dropdown.querySelector(dropdownSelectButtonSelector).appendChild(newDropdownText);
        }
      })
    });
  }
}

export default function NewDropdownSelect() {
  document
  .querySelectorAll(dropdownSelectSelector)
  .forEach((el) =>{
    new DropdownSelect(el)
  });
}
