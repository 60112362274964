<template>
  <div class="modal fade" ref="noticeModalRef">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body" style="display:flex; flex-direction:column; align-items: center;">
          <p style="font-size: 1.125rem;">{{ message }}</p>
          <div style="margin-top: 1rem;">
            <button v-if="nextUrl" type="button" class="mk-btn" title="前往" @click="toNext">前往</button>
            <button type="button" class="btn btn-secondary" style="margin-left: 6px" title="取消" data-bs-dismiss="modal">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps, onMounted, defineExpose} from "vue"
  import { Modal } from "bootstrap";

  const props = defineProps({
    message: {
      type: String,
      default: ''
    },
    nextUrl: {
      type: String,
      default: ''
    },
  });

  function toNext() {
    window.location.href = props.nextUrl;
  }

  // Modal控制
  let noticeModalRef = ref();
  let noticeModal = null;
  function _hide() {
    noticeModal.hide()
  }
  function _show() {
    noticeModal.show()
  }

  defineExpose({
    show: _show,
    hide: _hide,
  });

  onMounted(() => {
    noticeModal = new Modal(noticeModalRef.value);
  })
</script>
