


export default function TextareaApapt(){
  const textareaAdapts = document.querySelectorAll('.js-textarea-adapt')
  textareaAdapts.forEach(textareaAdapt => {
    textareaAdapt.addEventListener('input', function() {
      if(this.value !== '') { //防止空值時高度會跳動問題
        this.style.height = '2rem';
        this.style.height = (this.scrollHeight) + 'px'; // 設定 textarea 的高度為內容的高度
      } else {
        this.style.height = '2rem';
      }
    });

    textareaAdapt.addEventListener('keydown', function(event) {
      // 檢查是否按下 Enter 鍵
      if (event.key === 'Enter') {
          // 阻止 Enter 鍵的默認行為
          event.preventDefault();
      }
    });
  });
}


