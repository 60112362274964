<script setup>
import { onMounted, ref, defineProps } from "vue";
import { Modal } from "bootstrap";

defineProps({
  title: String,
});

let bsModalEl = ref();
let bsModal = null;

function _show() {
  bsModal.show();
}

function _hide() {
  bsModal.hide();
}

onMounted(() => {
  bsModal = new Modal(bsModalEl.value);
});

defineExpose({ 
  show: _show,
  hide: _hide
});
</script>

<template>
<div class="modal fade" ref="bsModalEl">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
      </div>

      <!-- Modal body -->
      <div class="modal-body" v-if="$slots.body">
        <slot name="body" />
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          取消
        </button>
      </div>
    </div>
  </div>
</div>
</template>