<template>
  <div class="step-order-create">
    <h2>
      恭喜您<div class="d-block d-lg-none"></div>完成訂購
    </h2>
    <div>
        我們已經收到您的訂單訊息<br>
        它將在數日後派送至您指定地址
    </div>
    <button @click="goToOrder()"
      class="mk-btn"
    >
      查看您的訂單
    </button>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits, onMounted } from "vue"
  import axios from 'axios';

  const props = defineProps({
    personalData: {
      type: Object,
      default: () => {}
    },
    landingSale: {
      type: Object,
      default: () => {
        return {
          isVisitor: false,
          slug: '',
        }
      }
    },
  });

  const orderNumber = ref('');

  const goToOrder = async () => {
    if (props.landingSale.isVisitor) {
      const slug = props.landingSale.slug;
      const order = window.btoa(orderNumber.value);
      const targetUrl =  `../${slug}/orders/${order}`;
      window.location.href = targetUrl
    } else {
      window.location.href = '../users/orders' 
    }
  }

  const emit = defineEmits([
    "storeAndChangeStepTo",
  ]);

  onMounted(() => {
    if (props.landingSale.isVisitor) {
      const slug = props.landingSale.slug;

      axios.get(`${slug}/cart_number`)
      .then(response => {
        const data = response.data.data;
        orderNumber.value = data?.number;
        console.log(window.btoa(orderNumber.value))
      })
    }

    emit('storeAndChangeStepTo', "3");
  })
</script>

<style lang="scss" scoped>
  .step-order-create{
    height: 280px;
    text-align: center;
    color: rgba(128, 128, 128, 1);
    h2{
      margin-top: 2rem;
      font-size: 3rem;
    }
    div{
      color: #666;
      line-height: 2;
    }
    button{
      margin-top: 1.5rem;
      padding-left: 1.75rem;
      padding-right: 1rem;
      letter-spacing: 0.75rem;
      font-size: 1.25rem;
    }
  }
  @media screen and (max-width:991px){
    .step-order-create{
      h2{
        margin-bottom: 1.5rem;
        line-height: 1.2;
        font-size: 2.5rem;
      }
      div{
        font-size: 0.85rem;
      }
      button{
        margin-top: 2.25rem;
        padding-right: 1.15rem;
        letter-spacing: 0.6rem;
        font-size: 1.1rem;
      }
    }
  }
 
</style>
