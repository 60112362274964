const closeEyeClass      = 'bi-eye-slash';
const openEyeClass       = 'bi-eye';
const initEyeIconClasses = ["bi", closeEyeClass];

export default class EyePasswordInput {
  constructor() {
    document
    .querySelectorAll(".js-eye-password-input")
    .forEach((el) => {
      const eyeEl = EyePasswordInput.setEyeIcon(el);
      eyeEl.addEventListener('click', EyePasswordInput.eventHandler.bind(el));
    });
  }

  static eventHandler(event) {
    const el = event.target;

    if(el.classList.contains(closeEyeClass)) {
      el.classList.replace(closeEyeClass, openEyeClass);
      this.setAttribute('type', 'text');
    } else {
      el.classList.replace(openEyeClass, closeEyeClass);
      this.setAttribute('type', 'password');
    }
  }

  static setEyeIcon(el) {
    const eyeElWrapper = document.createElement("div");
    eyeElWrapper.style["display"] = "flex";
    eyeElWrapper.style["align-items"] = "center";
    eyeElWrapper.style["margin-left"] = "-2rem";

    const eyeEl = document.createElement("i");
    eyeEl.classList.add(...initEyeIconClasses);

    eyeElWrapper.appendChild(eyeEl);
    el.parentElement.insertBefore(eyeElWrapper, el.nextSibling);

    return eyeEl;
  }
}
