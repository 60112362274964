import axios from "axios"
import {Debounce} from "~/javascripts/common.js";
import {zoomImageAnimationHandler} from '~/javascripts/app/zoom_in_image.js';
import { HandleErrorResponse } from "~/javascripts/utils.js"
import swal from "sweetalert";

export default function InitUserStoreProductsForm() {
  const formID = "store-product-form"

  const form   = document.getElementById(formID)
  if (!form) return false;
  document.addEventListener("DOMContentLoaded", () => {
    initInputPrice();
    initCoverImages();
    initOptionalImages();
    initCheckBox();
  });
}

function initInputPrice(){
  const profitInput = document.querySelector('.js-profit-input');
  profitInput.addEventListener('keyup', Debounce((e)=>{
    handlePrice(e.target)
  }, 500))

}

function handlePrice(input){
  const price = input.value;
  const designID = input.dataset.designId;
  var regex = /^0$|^[1-9]\d*$/;
  const validPattern = regex.test(price)
  if(!validPattern) return false;

  axios.get(`/api/store_products/profit`,{
      params: {
        design_id: designID,
        profit: price
      }
    })
    .then((res)=>{
      console.log(res.data)
      document.getElementById('js-store-product-profit').innerText = res.data.profit.profit
      document.getElementById('js-store-product-selling-price').innerText = res.data.profit.selling_price
      document.getElementById('js-store-product-unit-profit').innerText = res.data.profit.estimated_profit;
    }).catch((err)=>{
      HandleErrorResponse(err);
    })
}

// TODO 重構成class
function initCoverImages() {
  //全螢幕照片刪除btn
  const fullscreenContainer = document.querySelector('.js-fullscreen-container');
  const overlay = document.querySelector('.js-fullscreen-container__overlay');
  const zoomedBlock = document.querySelector('.js-zoomed-block');
  let originalExistIndex = -1;
  let destroyIndex = -1;

  function deletedCoverImgHandler(e) {
    const deletedIndex = parseInt(e.currentTarget.parentNode.getAttribute('data-bs-Image-index')); //轉成數字，方便等等的陣列操作
    const deletedImageID = parseInt(e.currentTarget.parentNode.getAttribute('data-bs-Image-ID')); //轉成數字，方便等等的陣列操作
    const destroyImageIndex = parseInt(e.currentTarget.parentNode.getAttribute('data-bs-image-destroy-index')); //轉成數字，方便等等的陣列操作


    deletedCoverImg(deletedIndex,deletedImageID,destroyImageIndex);
  };

  function deletedCoverImg(deletedIndex = -1, deletedImageID, destroyImageIndex){
    console.log('del');
    console.log(deletedIndex)
    let iElement = document.createElement('i');
    iElement.classList.add("bi","bi-trash3","delete-cover-img");
    overlay.appendChild(iElement);

    fullscreenContainer.addEventListener('click',removeOverlayImagElement,{ once: true });

    iElement.addEventListener('click', function (e) {
      if(originalExistIndex >= deletedIndex && originalExistIndex != -1){
        console.log('後端刪除input')

        const inputsBlock = document.getElementById('js-cover-iamge-inputs-section');
        let inputElementID  = document.createElement('input');
        let inputElement = document.createElement('input');
        inputElementID.setAttribute('type', 'hidden');

        // 设置 input 的 name 属性
        inputElementID.setAttribute('name', `store_product[store_product_images_attributes][${destroyIndex}][id]`);

        // 设置 input 的 id 属性
        inputElementID.setAttribute('id', `store_product_store_product_images_attributes_${destroyIndex}_id`);

        inputElementID.setAttribute('autocomplete', 'off');

        inputElementID.value = deletedImageID

        inputElement.setAttribute('type', 'hidden');

        // 设置 input 的 name 属性
        inputElement.setAttribute('name', `store_product[store_product_images_attributes][${destroyIndex}[_destroy]`);

        // 设置 input 的 id 属性
        inputElement.setAttribute('id', `store_product_store_product_images_attributes_${destroyIndex}_destroy`);

        inputElement.setAttribute('autocomplete', 'off');

        inputElement.value = 1;

        inputsBlock.appendChild(inputElementID)
        inputsBlock.appendChild(inputElement)
        originalExistIndex--;
        destroyIndex--;
      }

      console.log('this is aaaaaaaaaaaaa')
      removeImgElement(coverImageboxs,deletedIndex);

      // 清除overlay中的img element
      overlay.removeChild(overlay.querySelector('.delete-cover-img'));
      fullscreenContainer.removeEventListener('click', removeOverlayImagElement);

      // 原生放大照片方法close
      zoomedBlock.style=""
      document.body.style.overflow = 'auto';
      fullscreenContainer.style.display = 'none';

      e.stopImmediatePropagation();
    }, { once: true });
  }

  function removeOverlayImagElement() {
    overlay.removeChild(overlay.querySelector('.delete-cover-img'));
    console.log('this istrash')
  }


  // 初始化從controller獲得的資料
  const coverImages = document.querySelector('.js-cover-images');
  let coverImageDataArr = JSON.parse(coverImages.getAttribute('data-cover-images'));
  let addIndex = coverImageDataArr.length;
  let emptyIndex = addIndex + 1;
  const coverImageboxs = document.querySelectorAll('.js-cover-image-box');
  console.log(coverImageDataArr)



  // 畫面初始化
  // 圖片
  for(let index = 0; index < addIndex; index++){
    originalExistIndex++;
    coverImageboxs[index].setAttribute('role', 'button');
    coverImageboxs[index].addEventListener('click',zoomImageAnimationHandler );
    coverImageboxs[index].addEventListener('click',deletedCoverImgHandler);
  }
  // handleCoverImageInputType();
  addIconElement(coverImageboxs,addIndex);
  addSpanElement(coverImageboxs,emptyIndex);

  //add cover img modal
  const coverImageModal = document.querySelector('.js-modal-add-image-store-product');
  if(coverImageModal){
    let imageBoxIndex;

    coverImageModal.addEventListener('show.bs.modal', event => {
      console.log('open')
      console.log(addIndex,emptyIndex)

      coverImageModal.querySelectorAll(`.js-cover-image-input`).forEach(el=>el.style.display = 'none');

      const relatedDiv = event.relatedTarget;
      imageBoxIndex = relatedDiv.getAttribute('data-bs-Image-index');
      const coverImageInput = coverImageModal.querySelector(`.js-cover-image-input[data-input-index="${imageBoxIndex}"]`);
      console.log(coverImageInput);

      coverImageInput.style.display = 'block'

      const submitBtn = document.getElementById('submit_cover_img_modal');
      submitBtn.onclick = ()=>{
        console.log('submit')
        if(coverImageInput.files && coverImageInput.files[0]) {
          const fileSize = coverImageInput.files[0].size / 1024 / 1024; // 将字节转换为 MB
          if (fileSize > 2) {
            swal({
              title: "提醒",
              text: '文件大小不能超過 2MB。請選擇其它文件。',
              dangerMode: true,
            })
            coverImageInput.value = ''; // 清空文件输入框中的值
            return;
          }
        }

        if(coverImageInput.files && coverImageInput.files[0]) { // 純前端上傳預覽圖片
          coverImageInput.name = `store_product[store_product_images_attributes][${imageBoxIndex}][image]`;
          coverImageInput.id = `store_product_store_product_images_attributes_${imageBoxIndex}_image]`;
          var reader = new FileReader();

          reader.onload = function (e) {
            console.log('reader onload')
            removeIconElement(coverImageboxs,addIndex);
            addImgElement(coverImageboxs,addIndex,e.target.result);
            if(emptyIndex < 5) removeSpanElement(coverImageboxs,emptyIndex);
            emptyIndex++;
            addIndex++;
            if(addIndex < 5) addIconElement(coverImageboxs,addIndex);
            console.log(addIndex,emptyIndex);

            document.getElementById('close_cover_img_modal').click();
          };

          reader.readAsDataURL(coverImageInput.files[0]);

        }
      }
    })

    coverImageModal.addEventListener('hide.bs.modal', event => {
      console.log('close cover image modal.')
    })
  }
  //  DOMElementOperations
  function addIconElement(imageBoxs,index) { // 加入「新增封面照片的」icon到指定的imageBox
    let iElement = document.createElement('i');
    iElement.classList.add("bi","bi-plus","mk-image-box--is-add");
    imageBoxs[index].setAttribute('role', 'button');
    imageBoxs[index].parentNode.setAttribute('data-bs-toggle', 'modal');
    imageBoxs[index].parentNode.setAttribute('data-bs-target', '#addImageStoreProductModal');
    imageBoxs[index].appendChild(iElement);
  }

  function addSpanElement(imageBoxs,emptyStart){ // 加入「空的，但目前無法新增照片的數字」span元素到指定的imageBox
    for(let index = emptyStart; index < 5; index++){
      let spanElement = document.createElement('span');
      spanElement.classList.add("mk-image-box--has-text");
      spanElement.textContent = `${index + 1}/5`;
      imageBoxs[index].appendChild(spanElement);
    }
  }
  function addImgElement(imageBoxs,index,imgSrc){ //加入照片元素
    let imgElement = document.createElement('img');
    imgElement.src = imgSrc;
    imgElement.alt = 'Image';
    imageBoxs[index].setAttribute('role', 'button');
    imageBoxs[index].addEventListener('click',zoomImageAnimationHandler );
    imageBoxs[index].addEventListener('click',deletedCoverImgHandler);
    imageBoxs[index].appendChild(imgElement);

    // handleCoverImageInputType();
  }

  function removeIconElement(imageBoxs,index){ // 從指定的imageBox，移除「新增封面照片的」icon
    let iElement = imageBoxs[index].querySelector('i');
    imageBoxs[index].removeAttribute('role', 'button');
    imageBoxs[index].parentNode.removeAttribute('data-bs-toggle', 'modal');
    imageBoxs[index].parentNode.removeAttribute('data-bs-target', '#addImageStoreProductModal');
    imageBoxs[index].removeChild(iElement);
  }

  function removeSpanElement(imageBoxs,index){  // 從指定的imageBox，移除「空的，但目前無法新增照片的數字」span元素
    let spanElement = imageBoxs[index].querySelector('span');
    imageBoxs[index].removeChild(spanElement);
  }

  function removeImgElement(imageBoxs,index){ //移除照片元素
    console.log('remove before',addIndex,emptyIndex);
    if(addIndex < 5) removeIconElement(imageBoxs,addIndex);
    addIndex--;

    const coverImageInputBlock =  document.querySelector(`.js-cover-image-input[data-input-index="${index}"]`).parentNode;
    coverImageInputBlock.removeChild(document.querySelector(`.js-cover-image-input[data-input-index="${index}"]`));

    for(let currentIndex = index; currentIndex < addIndex; currentIndex++ ){
      const nextIndex = currentIndex + 1;
      if(nextIndex > originalExistIndex + 1){
        console.log('input modigy')
        document.querySelector(`.js-cover-image-input[data-input-index="${nextIndex}"]`).name = `store_product[store_product_images_attributes][${nextIndex - 1}][image]`;
        document.querySelector(`.js-cover-image-input[data-input-index="${nextIndex}"]`).id = `store_product_store_product_images_attributes_${nextIndex - 1}_image]`;
      } else {
        console.log(imageBoxs);
        imageBoxs[currentIndex].parentNode.setAttribute('data-bs-image-id', `${imageBoxs[nextIndex].parentNode.getAttribute('data-bs-image-id')}`)
        imageBoxs[currentIndex].parentNode.setAttribute('data-bs-image-destroy-index', `${imageBoxs[nextIndex].parentNode.getAttribute('data-bs-image-destroy-index')}`)

      }

      document.querySelector(`.js-cover-image-input[data-input-index="${nextIndex}"]`).setAttribute('data-input-index', `${nextIndex - 1}`);

      imageBoxs[currentIndex].querySelector('img').src = imageBoxs[nextIndex].querySelector('img').src;
    }

    imageBoxs[addIndex].parentNode.removeAttribute('data-bs-image-destroy-index')
    let inputElement = document.createElement('input');
    inputElement.setAttribute('type', 'file');
    inputElement.setAttribute('class', 'form-control mk-form-control mka-file-input js-cover-image-input');
    inputElement.setAttribute('style', 'display:none');
    inputElement.setAttribute('accept', 'image/*');
    inputElement.setAttribute('data-input-index', `${addIndex}`);
    addIndex === 0 ? coverImageInputBlock.insertBefore(inputElement,document.querySelector(`.js-cover-image-input[data-input-index="${1}"]`))
    : coverImageInputBlock.insertBefore(inputElement,document.querySelector(`.js-cover-image-input[data-input-index="${addIndex - 1}"]`).nextSibling)


    imageBoxs[addIndex].removeChild(imageBoxs[addIndex].querySelector('img'));
    imageBoxs[addIndex].removeEventListener('click', zoomImageAnimationHandler);
    imageBoxs[addIndex].removeEventListener('click', deletedCoverImgHandler);
    addIconElement(imageBoxs,addIndex);




    for(let currentIndex = emptyIndex; currentIndex < 5; currentIndex++ ){
      removeSpanElement(imageBoxs,currentIndex)
    }
    emptyIndex--;
    if(emptyIndex < 5) addSpanElement(imageBoxs,emptyIndex);
    // handleCoverImageInputType();
    console.log('remove after',addIndex,emptyIndex)
  }


}

function initOptionalImages() {
  const optionalImages = document.querySelectorAll('.js-optional-image')
  const selectedImages = document.querySelector('.js-selected-images');
  let selectedArr = [];
  optionalImages.forEach(image => {
    const imageObj = {
      id: image.getAttribute('data-image-id'),
      isSelected: image.getAttribute('data-image-selected') === 'true',
    }

    const checkbox = image.querySelector('input[type=checkbox]');
    const checkDisplay = image.querySelector('.js-check-display');
    const imageFilter = document.querySelector(`.js-image-filter-${imageObj.id}`);
   
    if(imageObj.isSelected) {
      checkbox.checked = true;
      imageFilter.classList.remove('d-block');
      checkDisplay.classList.add('information-image__check--active')
      selectedArr.push(imageObj.id);
    } else {
      checkbox.checked = false;
      imageFilter.classList.add('d-block');
      checkDisplay.classList.remove('information-image__check--active')
      selectedArr = selectedArr.filter(id => id !== imageObj.id);
    }

    selectedImages.value = selectedArr.join(",");

    image.addEventListener('click',() => {
      checkbox.checked = !checkbox.checked;
      if(checkbox.checked) {
        imageFilter.classList.remove('d-block');
        checkDisplay.classList.add('information-image__check--active')
        selectedArr.push(imageObj.id);
      } else {
        imageFilter.classList.add('d-block');
        checkDisplay.classList.remove('information-image__check--active')
        selectedArr = selectedArr.filter(id => id !== imageObj.id);
      }
      selectedImages.value = selectedArr.join(",");
    })
  });
}

function initCheckBox(){
  const checkboxbtns= document.querySelectorAll('.js-checkbox-btn')
  checkboxbtns.forEach(checkbox => {
    checkbox.addEventListener('click',function(){
      this.checked ? this.value = 'accept': this.value = ''
    })
  });
}
